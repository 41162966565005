import { commonConstants } from "../constants";
import { RequestService, FunctionService } from "../services";
import Cookies from "js-cookie";
const token = Cookies.get("auth");
const store_id = Cookies.get("c_store");
const lang = Cookies.get("lang");

export const commonActions = {
  Getwilayas,
  Getcommunes,
  GetWarehouse,
  GetVehiculeTypes,
  ClearStore,
  GetDelivery,
  GetCountry,
  GetInfoMaystro,
  GetStopDeskWilayas,
  GetDeliveryV2,
  GetPickupPoints,
  ClearPickupPointsData
};

function GetCountry() {
  return (dispatch) => {
    dispatch(FunctionService.request(commonConstants.GET_COUNTRY_REQUEST));
    RequestService.GetRequest("shared/countries/")
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, commonConstants.GET_COUNTRY_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            commonConstants.GET_COUNTRY_FAILURE
          )
        );
      });
  };
}

function Getwilayas(country_id = 1) {
  return (dispatch) => {
    dispatch(FunctionService.request(commonConstants.GET_WILAYAS_REQUEST));
    RequestService.GetRequest(`shared/wilayas/?country_id=${country_id}`, {})
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, commonConstants.GET_WILAYAS_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            commonConstants.GET_WILAYAS_FAILURE
          )
        );
      });
  };
}

function GetStopDeskWilayas(country_id = 1){
  return (dispatch) => {
    dispatch(FunctionService.request(commonConstants.GET_STOP_DESK_WILAYAS_REQUEST));
    RequestService.GetRequest(`base/wilayas/?country=${country_id}&is_stop_desk=${true}`,{
      Authorization: "Token " + token,
    }, )
      .then((res) => {
        let dataSDWilayas = []; 
        if(res.data.results){
          dataSDWilayas = res.data.results;
        }else{
          dataSDWilayas = res.data;
        }
        dispatch(
          FunctionService.succes(dataSDWilayas, commonConstants.GET_STOP_DESK_WILAYAS_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            commonConstants.GET_STOP_DESK_WILAYAS_FAILURE
          )
        );
      });
  };
};

function Getcommunes(params) {
  return (dispatch) => {
    dispatch(FunctionService.request(commonConstants.GET_COMMUNE_REQUEST));
    RequestService.GetRequest(`shared/communes/${params}`, {})
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, commonConstants.GET_COMMUNE_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            commonConstants.GET_COMMUNE_FAILURE
          )
        );
      });
  };
}
function GetWarehouse() {
  return (dispatch) => {
    dispatch(FunctionService.request(commonConstants.GET_WAREHOUSE_REQUEST));
    RequestService.GetRequest("stores/add_warehouse/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            commonConstants.GET_WAREHOUSE_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            commonConstants.GET_WAREHOUSE_FAILURE
          )
        );
      });
  };
};
function GetVehiculeTypes() {
  return (dispatch) => {
    dispatch(
      FunctionService.request(commonConstants.GET_VEHICULETYPES_REQUEST)
    );
    RequestService.GetRequest("shared/vehicle_types/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            commonConstants.GET_VEHICULETYPES_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            commonConstants.GET_VEHICULETYPES_FAILURE
          )
        );
      });
  };
}
function ClearStore(DeletConst) {
  return (dispatch) => {
    dispatch(clear(DeletConst));
  };
  function clear(DeletConst) {
    return {
      type: DeletConst,
      payload: {},
    };
  }
}

function GetDelivery(commune, express, delivery_type) {
  let expressTF = express === false ? "False" : "True";

  return (dispatch) => {
    dispatch(FunctionService.request(commonConstants.GET_DELIVERY_REQUEST));
    let url =
      "stores/orders/delivery_prices/?commune=" +
      commune +
      "&express=" +
      expressTF +
      "&delivery_type=" +
      delivery_type;
    RequestService.GetRequest(url, { Authorization: "Token " + token })
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, commonConstants.GET_DELIVERY_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, commonConstants.GET_DELIVERY_FAILURE)
        );
      });
  };
};

function GetDeliveryV2(commune, express) {
  let expressTF = express === false ? "False" : "True";

  return (dispatch) => {
    dispatch(
      FunctionService.request(commonConstants.GET_DELIVERY_HOME_SD_PP_REQUEST)
    );
    let url =
      "stores/orders/delivery_prices/?version=2.0&commune=" +
      commune +
      "&express=" +
      expressTF;
    RequestService.GetRequest(url, { Authorization: "Token " + token })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data, 
            commonConstants.GET_DELIVERY_HOME_SD_PP_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err, 
            commonConstants.GET_DELIVERY_HOME_SD_PP_FAILURE
          )
        );
      });
  };
};

function GetPickupPoints(commune_id) {
  return (dispatch) => {
    dispatch(FunctionService.request(commonConstants.GET_PICKUP_POINTS_REQUEST));
    RequestService.GetRequest(`base/pickup-points/?commune=${commune_id}`, {
      Authorization: "Token " + token
    })
    .then(res => dispatch(FunctionService.succes(res.data, commonConstants.GET_PICKUP_POINTS_SUCCESS)))
    .catch(err => dispatch(FunctionService.failure(err, commonConstants.GET_PICKUP_POINTS_FAILURE)));
  }
};

function ClearPickupPointsData() {
  return (dispatch) => {
    dispatch(FunctionService.request(commonConstants.CLEAR_PICKUP_POINTS));
  }
}

function GetInfoMaystro(country_id) {
  return (dispatch) => {
    dispatch(FunctionService.request(commonConstants.GET_INFO_REQUEST));
    RequestService.GetRequest(
      "shared/maystro_informations/?country_id=" + country_id
    )
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, commonConstants.GET_INFO_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, commonConstants.GET_INFO_FAILURE)
        );
      });
  };
}
