import React, { useEffect, useState } from 'react';
import Button from '../../../_Common/Button';
import { Translate } from '../../../../utils/lang/translate';
import GroupeForm from '../../../_Common/GroupeForm';
import { checkPhoneNumberValidity } from '../../../../utils/helpers';

type CustomerInfoType = {
  name: string,
  phone: string,
  second_phone: string | null,
};

interface NamePhoneError {
  name: string | null,
  phone: string | null,
  secondPhone: string | null
};

interface CustomerInfoFormProps {
  onFormSubmit: (data: CustomerInfoType) => void,
  customerInfo?: CustomerInfoType | null,
};

const CustomerInfoForm = ({
  onFormSubmit,
  customerInfo
}: CustomerInfoFormProps) => {

  const INITIAL_NAME_PHONE_ERROR_STATE: NamePhoneError = {
    name: null,
    phone: null,
    secondPhone: null,
  };

  const [customerName, setCustomerName] = useState("");
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [hideInput, setHideInput] = useState<number>(10);
  const [customerSecondPhone, setCustomerSecondPhone] = useState("");
  const [hideInput2, setHideInput2] = useState<number>(10);
  const [customerNamePhoneError, setCustomerNamePhoneError] = useState<NamePhoneError>(INITIAL_NAME_PHONE_ERROR_STATE);

  const disableSubmit: boolean = 
    customerNamePhoneError.name !== null || 
    customerNamePhoneError.phone !== null || 
    customerNamePhoneError.secondPhone  !== null
  ;

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>, second?: boolean) => {
    const value = e.target.value;
    if(second){
      setCustomerSecondPhone(value);
    }else{
      setCustomerPhoneNumber(value);
    }
    const limitPlusDZ = 13;
    const limitPlusTN = 12;
    const limitDZ = 10;
    const StartNumberPhone = value.substring(0, 4);
    const StartNumberPhone0 = value.substring(0, 1);

    if (StartNumberPhone === "+213") {
      second ? setHideInput2(limitPlusDZ) : setHideInput(limitPlusDZ);
    }
    if (StartNumberPhone === "+216") {
      second ? setHideInput2(limitPlusTN) : setHideInput(limitPlusTN);
    }
    if (StartNumberPhone0 === "0") {
      second ? setHideInput2(limitDZ) : setHideInput(limitDZ);
    }
    if(checkPhoneNumberValidity(value)){
      if(customerNamePhoneError.phone && !second){
        setCustomerNamePhoneError(prev => ({ ...prev, phone: null }));
      }
      if(customerNamePhoneError.secondPhone && second) {
        setCustomerNamePhoneError(prev => ({ ...prev, secondPhone: null }));
      }
    }
  };

  const handleCustomerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if(value !== "" && customerNamePhoneError.name) setCustomerNamePhoneError(prev => ({ ...prev, name: null }));
    setCustomerName(value);
  };

  const handleSubmit = () => {
    if(customerName === "") setCustomerNamePhoneError(prev => ({ ...prev, name: Translate("error", "eo4") }));
    if(!checkPhoneNumberValidity(customerPhoneNumber)) setCustomerNamePhoneError(prev => ({ ...prev, phone: Translate("error", "invalidphone") }));
    if(customerSecondPhone !== "" && !checkPhoneNumberValidity(customerSecondPhone)) setCustomerNamePhoneError(prev => ({ ...prev, secondPhone: Translate("error", "invalidphone") }));
    if(
      customerName && checkPhoneNumberValidity(customerPhoneNumber) &&
      (customerSecondPhone === "" || customerSecondPhone !== "" && checkPhoneNumberValidity(customerSecondPhone))
    ){
      onFormSubmit({
        name: customerName,
        phone: customerPhoneNumber,
        second_phone: customerSecondPhone
      });
    }
  };
  useEffect(() => {
    if(customerInfo){
      setCustomerName(customerInfo.name);
      setCustomerPhoneNumber(customerInfo.phone);
      setCustomerSecondPhone(customerInfo.second_phone ?? "");
    }
  }, [customerInfo]);

  return (
    <>
      <div className="InFlx Stclmnf" style={{ gap: "16px" }}>
        <h3 className="DlMg">{Translate("orders", "orderinfo")}</h3>
        <div 
          className="InFlx" 
          style={{ flexWrap: "wrap", justifyContent: "space-between", gap: "30px 16px" }}
        >
          <GroupeForm
            id={"customername"}
            name={"customername"}
            placeholder={Translate("orders", "customername")}
            text={Translate("orders", "customername")}
            type={"text"}
            value={customerName}
            workfun={handleCustomerNameChange}
            error={customerNamePhoneError.name}
            stclass={customerNamePhoneError.name ? "borderError" : ""}
            style={{ flex: "0 1 500px" }}
          />
          <GroupeForm
            id={"customerphonenumber"}
            name={"customerphonenumber"}
            placeholder={Translate("orders", "customerphonenumber")}
            text={Translate("orders", "customerphonenumber")}
            type={"phone"}
            pattern="^(((\+216)?[1-9]\d{7})|((0?|(00|\+)?213)[56789]\d{8}))$"
            value={customerPhoneNumber}
            workfun={handlePhoneNumberChange}
            maxLength={hideInput}
            error={customerNamePhoneError.phone}
            stclass={customerNamePhoneError.phone ? "borderError" : ""}
            style={{ flex: "0 1 500px" }}
          />
          <GroupeForm
            id={"customersecondphonenumber"}
            name={"customersecondphonenumber"}
            placeholder={Translate("orders", "customersecondphone")}
            text={Translate("orders", "customersecondphone")}
            type={"phone"}
            pattern="^(((\+216)?[1-9]\d{7})|((0?|(00|\+)?213)[56789]\d{8}))$"
            value={customerSecondPhone}
            workfun={(e: React.ChangeEvent<HTMLInputElement>) => handlePhoneNumberChange(e, true)}
            maxLength={hideInput2}
            error={customerNamePhoneError.secondPhone}
            stclass={customerNamePhoneError.secondPhone ? "borderError" : ""}
            style={{ flex: "0 1 500px" }}
            optional={true}
          />
        </div>
      </div>
      <div className="FlWd" style={{ height: "1px", backgroundColor: "var(--fntClr)", opacity: 0.2 }}/>
      <div className="MrAot" style={{ width: "300px" }}>
        <Button
          gray={disableSubmit}
          disabled={disableSubmit} 
          onClick={handleSubmit}
          BtnText={Translate("exchanges", "next")} 
          style={{ padding: "10px 0" }} 
        />
      </div>
    </>
  );
}
 
export default CustomerInfoForm;