import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { alertActions, commonActions } from '../../../../actions';
import { CommuneInterface, PickupPointType, RequestStatus, wilayaType } from '../../../../types';
import { MAX_TOTAL_PRICE_DZ, MAX_TOTAL_PRICE_TN } from '../../../../constants/orders.constants';
import Cookies from 'js-cookie';
import { Translate } from '../../../../utils/lang/translate';
import { Select } from '../../_Common';
import { DeliveryOption } from './DeliveryOption';
import GroupeForm from '../../../_Common/GroupeForm';
import CheckBoxForm from '../../../_Common/CheckBoxForm';
import { SmallCoinSvG, TowCoinsSvg } from '../../_Common/IconSvg';
import Button from '../../../_Common/Button';

const lang = Cookies.get("lang");

type DeliveryPriceData = { 
  home_delivery_price: number, 
  sd_delivery_price: number,
  min_home_total_price: number, 
  min_sd_total_price: number,
  current_points: number, 
  required_points_home: number,
  required_points_sd: number, 
};

interface DeliveryDetailsError {
  wilaya: string | null,
  commune: string | null,
  pickup_point: string | null,
  price: string | null
};

type DeliveryDetailsType = {
  wilaya: number | null,
  commune: number | null,
  pickup_point: number | null,
  total_price: string | null,
  destination_text: string | null,
  delivery_type: 1 | 2 | 3,
  express: boolean,
  use_points: boolean,
  note_to_driver: string | null,
};

interface DeliveryDetailsFormProps {
  dtStoreinfo: any,
  GetingWilaya: "0" | "1" | "2",
  GetWilayas: (country_id: number) => void,
  dataWilaya: wilayaType[],
  GetigCommune: "0" | "1" | "2",
  DataCommune: CommuneInterface[],
  Getcommunes: (query: string) => void,
  GetDeliveryV2: (commune: number, express: boolean) => void,
  GettingDeliveryV2: RequestStatus,
  dataDeliveryV2: DeliveryPriceData,
  GetPickupPoints: (commune_id: number) => void,
  GettingPickupPoints: RequestStatus,
  dataPickupPoints: PickupPointType[],
  onFormSubmit: (data: DeliveryDetailsType) => void,
  ClearPickupPointsData: () => void,
  SendAlert: (code: string, text: string, action: string) => void,
  loading: boolean,
  orderDeliveryDetails?: DeliveryDetailsType | null,
};

const DeliveryDetailsForm = ({
  GetDeliveryV2,
  dataDeliveryV2,
  GettingDeliveryV2,
  GetWilayas,
  dataWilaya,
  GetingWilaya,
  Getcommunes,
  DataCommune,
  GetigCommune,
  GetPickupPoints,
  dataPickupPoints,
  GettingPickupPoints,
  ClearPickupPointsData,
  dtStoreinfo,
  SendAlert,
  onFormSubmit,
  loading,
  orderDeliveryDetails
}: DeliveryDetailsFormProps) => {

  const INITIAL_DELIVERY_DETAILS_ERROR: DeliveryDetailsError = {
    commune: null,
    pickup_point: null,
    price: null,
    wilaya: null
  };

  const [noteToDriver, setNoteToDriver] = useState("");
  const [totalPrice, setTotalPrice] = useState<string>("0");
  const [selectedOption, setSelectedOption] = useState<"HOME_DELIVERY" | "STOPDESK_PICKUP_POINT">("HOME_DELIVERY");
  const [wilaya, setWilaya] = useState<number | null>(null);
  const [commune, setCommune] = useState<number | null>(null);
  const [pickupPoint, setPickupPoint] = useState<number | null>(null);
  const [adress, setAdress] = useState<string>("");
  const [pointsDelivery, setPointsDelivery] = useState<boolean>(false);
  const [deliveryDetailsError, setDeliveryDetailsError] = useState<DeliveryDetailsError>(INITIAL_DELIVERY_DETAILS_ERROR);

  const store_currency = lang === "ar" ? dtStoreinfo.country.ar_currency : dtStoreinfo.country.lt_currency;

  const disabled_sd_pp: boolean = 
    GettingPickupPoints === '2' &&
    dataPickupPoints.length === 0 || 
    GettingPickupPoints === '3'
  ;

  const show_sd_pp: boolean = 
    GettingPickupPoints === '2' && 
    selectedOption === 'STOPDESK_PICKUP_POINT' && 
    dataPickupPoints.length > 0
  ; 
                      
  const delivery_price = selectedOption === 'HOME_DELIVERY' 
    ? dataDeliveryV2?.home_delivery_price ?? 0 
    : dataDeliveryV2?.sd_delivery_price ?? 0
  ;

  const sd_pp_discount: number | null = 
    GettingDeliveryV2 === '2' &&
    dataDeliveryV2.home_delivery_price !== null &&
    dataDeliveryV2.sd_delivery_price !== null && 
    dataDeliveryV2.home_delivery_price - dataDeliveryV2.sd_delivery_price > 0
    ? dataDeliveryV2.home_delivery_price - dataDeliveryV2.sd_delivery_price
    : null
  ; 
  
  const min_total_price = selectedOption === "HOME_DELIVERY" 
    ? dataDeliveryV2?.min_home_total_price ?? 0 
    : dataDeliveryV2?.min_sd_total_price ?? 0
  ;
  
  const max_total_price = dtStoreinfo.country.id === 1 
    ? MAX_TOTAL_PRICE_DZ 
    : MAX_TOTAL_PRICE_TN
  ;

  const required_points = selectedOption === 'HOME_DELIVERY'
    ? dataDeliveryV2?.required_points_home ?? 0
    : dataDeliveryV2?.required_points_sd ?? 0
  ;

  const sufficient_points: boolean = (dataDeliveryV2?.current_points ?? -1) > required_points; 
  
  const pointsDeliveryCheck: boolean = GettingDeliveryV2 === '2' 
    ? pointsDelivery && sufficient_points
    : pointsDelivery
  ;

  const disablePointsDelivery: boolean = GettingDeliveryV2 === '2' && !sufficient_points;
  
  const converted_price = dtStoreinfo.country.id === 1 ? parseInt(totalPrice): parseFloat(totalPrice); 
  const price_diff: number = pointsDelivery
    ? converted_price
    : converted_price - delivery_price
  ;

  const disableSubmit: boolean =
    deliveryDetailsError.commune !== null || 
    deliveryDetailsError.wilaya !== null || 
    deliveryDetailsError.pickup_point !== null ||
    deliveryDetailsError.price !== null ||
    loading; 
  ;

  const buttonTitle = 
    loading 
    ? Translate("alert", "load") 
    : Translate("shop", "confirm")
  ;

  const handleWilayaChange = (option: wilayaType, _: string) => {
    setWilaya(option?.[0] ?? null);
    if(option){
      Getcommunes(`?wilaya=${option[0]}`);
      if(deliveryDetailsError.wilaya) setDeliveryDetailsError(prev => ({ ...prev, wilaya: null }));
      if(pickupPoint) setPickupPoint(null);
      if(commune) setCommune(null);
      ClearPickupPointsData();
    }
  };

  const handleCommuneChange = (option: CommuneInterface, _: string) => {
    setCommune(option?.id ?? null);
    if(option){
      GetDeliveryV2(option.id, false);
      GetPickupPoints(option.id);
      if(deliveryDetailsError.commune) setDeliveryDetailsError(prev => ({ ...prev, commune: null }));
    }
    if(pickupPoint) setPickupPoint(null);
    ClearPickupPointsData();
  };

  const handlePickupPointChange = (option: PickupPointType) => {
    if(!pickupPoint) setPickupPoint(option.pickup_point);
    else if(pickupPoint !== option.pickup_point){
      setPickupPoint(option.pickup_point);
    }
  };

  const handleSelectStopDesk = () => {
    setSelectedOption(prev => prev === "HOME_DELIVERY" ? "STOPDESK_PICKUP_POINT" : prev);
    if(!pickupPoint && dataPickupPoints?.length > 0) setPickupPoint(dataPickupPoints[0].pickup_point);
  }

  const handleSelectHomeDelivery = () => {
    setSelectedOption("HOME_DELIVERY");
    if(deliveryDetailsError.pickup_point) setDeliveryDetailsError(prev => ({ ...prev, pickup_point: null }));
  };

  const handleAdressChange = (e: React.ChangeEvent<HTMLInputElement>) => setAdress(e.target.value);

  const handleTotalPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
    const value = e.target.value;
    const prix = value === "" ? "0" : value.replace(/\D/g, "");
    
    const converted_price = dtStoreinfo.country.id === 1 ? parseInt(prix) : parseFloat(prix);

    setTotalPrice(prix);

    if(
      deliveryDetailsError.price &&
      converted_price > min_total_price &&
      converted_price < max_total_price
    ){
      setDeliveryDetailsError(prev => ({ ...prev, price: null }));
    }
  };

  const handleNoteToDriverChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => setNoteToDriver(e.target.value);

  const handlePointsDeliveryChange = () => {
    if(GettingDeliveryV2 === '2' && !sufficient_points) return;
    else setPointsDelivery(prev => !prev);
  };

  const handleSubmit = () => {
    let submit: boolean = true;
      
    if(!wilaya) {
      setDeliveryDetailsError(prev => ({ ...prev, wilaya: Translate("error", "reqfield") }));
      submit = false;
    }
    
    if(!commune) {
      setDeliveryDetailsError(prev => ({ ...prev, commune: Translate("error", "reqfield") }));
      submit = false;
    }
    
    if(selectedOption === 'STOPDESK_PICKUP_POINT' && dataPickupPoints.length === 0) {
      setDeliveryDetailsError(prev => ({ ...prev, pickup_point: Translate("error", "reqfield") }));
      submit = false;
    }
    
    if(converted_price < min_total_price){
      setDeliveryDetailsError(prev => ({ ...prev, price: `Total price needs to be equal or superior to ${min_total_price}` }));
      submit = false;
    }else if(converted_price > max_total_price){
      setDeliveryDetailsError(prev => ({ ...prev, price: `Total price needs to be inferior or equal to ${max_total_price}` }));
      submit = false;
    };

    if(submit) {
      onFormSubmit({
        wilaya: wilaya,
        commune: commune,
        delivery_type: 
          selectedOption === 'HOME_DELIVERY' ? 
          1 : (
            dataPickupPoints.find(
              pp => pp.pickup_point === pickupPoint
            )?.delivery_type ?? 2
          ),
        destination_text: adress,
        express: false,
        note_to_driver: noteToDriver,
        use_points: pointsDelivery && sufficient_points,
        total_price: totalPrice,
        pickup_point: pickupPoint,
      });
    }
  };

  useEffect(() => {
    
    if(!GetingWilaya || GetingWilaya === '2'){
      GetWilayas(dtStoreinfo.country.id);
    };

    if(orderDeliveryDetails?.wilaya && !GetigCommune) 
      Getcommunes(`?wilaya=${orderDeliveryDetails.wilaya}`)
    ;
    if(orderDeliveryDetails?.commune && !GettingPickupPoints) {
      GetPickupPoints(orderDeliveryDetails.commune);
      GetDeliveryV2(orderDeliveryDetails.commune, false);
    }

    if(orderDeliveryDetails){
      setTotalPrice(orderDeliveryDetails.total_price ?? "");
      setNoteToDriver(orderDeliveryDetails.note_to_driver ?? "");
      setSelectedOption(orderDeliveryDetails.delivery_type === 1 ? "HOME_DELIVERY" : "STOPDESK_PICKUP_POINT");
      setAdress(orderDeliveryDetails.destination_text ?? "");
      setPointsDelivery(orderDeliveryDetails.use_points);
      setWilaya(orderDeliveryDetails.wilaya);
      setPickupPoint(orderDeliveryDetails.pickup_point);
      setCommune(orderDeliveryDetails.commune);
    }

  }, [orderDeliveryDetails]);

  useEffect(() => {
    
    if(
      GetingWilaya === '2' || 
      GetigCommune === '2' ||
      GettingDeliveryV2 === '3' ||
      GettingPickupPoints === '3'
    ) SendAlert("50", "Failed to fetch data!", "");

    if(
      GettingPickupPoints === "2" &&  
      selectedOption === 'STOPDESK_PICKUP_POINT' &&
      !pickupPoint
    ) {
      if(dataPickupPoints.length > 0){
        if(orderDeliveryDetails && orderDeliveryDetails.pickup_point){
          setPickupPoint(
            dataPickupPoints.find(
              (item, _) => (
                item.pickup_point === orderDeliveryDetails.pickup_point 
                && item.commune === orderDeliveryDetails.commune
              )
            )?.pickup_point ?? dataPickupPoints[0].pickup_point
          );
        }else{
          setPickupPoint(dataPickupPoints[0].pickup_point);
        }
      }else{
        setSelectedOption('HOME_DELIVERY');
      }

      if(deliveryDetailsError.pickup_point) setDeliveryDetailsError(prev => ({ ...prev, pickup_point: null }));
    };

    if(GettingDeliveryV2 === '2' && !sufficient_points) setPointsDelivery(false);

  }, [GetingWilaya, GetigCommune, GettingDeliveryV2, GettingPickupPoints]);

  return (
    <>
      <div className="InFlx Stclmnf" style={{ gap: "16px" }}>
        <h3 className="DlMg">{Translate("orders", "deliverydetails")}</h3>
        <div 
          className="InFlx" 
          style={{ gap: "20px" }}
        >
          <div className="InFlx Stclmnf" style={{ flex: "1 1 0px", gap: "24px" }}>
            <div className="InFlx" style={{ gap: "10px" }}>
              <Select
                label={Translate("orders", "city")}
                placholder={Translate("orders", "citySearch")}
                search={true}
                Options={GetingWilaya !== '1' ? [] : dataWilaya}
                fieldShow={1}
                name="city"
                loading={GetingWilaya === '0'}
                value={
                  (dataWilaya ?? []).find(
                    w => w[0] === wilaya
                  ) ?? null
                }
                onChange={handleWilayaChange}
                maxSize="200px"
                disabled={GetingWilaya === '0' || GetingWilaya === '2'}
                error={deliveryDetailsError.wilaya}
                containerClass={
                  deliveryDetailsError.wilaya
                  ? "borderError" : ""
                }
              />
              <Select
                label={Translate("orders", "district")}
                placholder={Translate("orders", "districtSearch")}
                search={true}
                Options={GetigCommune !== '1' ? [] : DataCommune}
                fieldShow={"name"}
                name="district"
                loading={GetigCommune === '0' || GetingWilaya === '0'}
                value={(GetigCommune === '1' && DataCommune.find(item => item.id === commune)) ?? null}
                onChange={handleCommuneChange}
                maxSize="200px"
                error={deliveryDetailsError.commune}
                containerClass={
                  deliveryDetailsError.commune
                  ? "borderError" : ""
                }
              />
            </div>
            <div className="InFlx Stclmnf" style={{ gap: "16px" }}>
              <h4 className="DlMg">{Translate("orders", "deliverymethod")}</h4>
              {
                deliveryDetailsError.pickup_point !== null
                &&
                <p className="DlMg StRedClr">{deliveryDetailsError.pickup_point}</p>
              }
              <DeliveryOption 
                isSelected={selectedOption === 'STOPDESK_PICKUP_POINT'}  
                currency={
                  lang === "ar" 
                  ? dtStoreinfo.country.ar_currency 
                  : dtStoreinfo.country.lt_currency
                } 
                price={
                  GettingDeliveryV2 === '2'
                  ?(dataDeliveryV2?.sd_delivery_price ?? 0) : 0
                }
                text={Translate("orders", "stopdeskorpp")}
                handler={handleSelectStopDesk}
                disabled={disabled_sd_pp}
                discount={sd_pp_discount}
              />
              {
                show_sd_pp 
                &&
                <>
                  <h4 className="DlMg">{Translate("orders", "selectdestination")}</h4>
                  {
                    dataPickupPoints.map((point, index) => (
                      <DeliveryOption 
                        key={index}
                        isSelected={pickupPoint === point.pickup_point} 
                        currency={
                          lang === "ar" 
                          ? dtStoreinfo.country.ar_currency 
                          : dtStoreinfo.country.lt_currency
                        } 
                        price={
                          GettingDeliveryV2 === '2'
                          ? (dataDeliveryV2?.sd_delivery_price ?? 0) : 0
                        }
                        text={lang === "ar" && point.name_ar ? point.name_ar : point.name_lt}
                        handler={() => handlePickupPointChange(point)}
                      />
                    ))
                  }
                </>  
              }
              <div className="InFlx AlgnItm" style={{ gap: "10px" }}>
                <div className="FlWd" style={{ height: "1px", backgroundColor: "var(--fntClr)", opacity: 0.2 }}/>
                <div className="StOpcVal">{Translate("titles", "or")}</div>
                <div className="FlWd" style={{ height: "1px", backgroundColor: "var(--fntClr)", opacity: 0.2 }}/>
              </div>
              <DeliveryOption 
                isSelected={selectedOption === "HOME_DELIVERY"} 
                currency={
                  lang === "ar" 
                  ? dtStoreinfo.country.ar_currency 
                  : dtStoreinfo.country.lt_currency
                } 
                price={dataDeliveryV2?.home_delivery_price ?? 0}
                text={Translate("orders", "home")}
                handler={handleSelectHomeDelivery}
              >
                {
                  selectedOption === 'HOME_DELIVERY'
                  &&
                  <GroupeForm
                    id={"adresse"}
                    name={"adresse"}
                    placeholder={Translate("orders", "adresse")}
                    type={"text"}
                    value={adress}
                    workfun={handleAdressChange}
                  />
                }
              </DeliveryOption>
            </div>
            <GroupeForm
              id={"totalprice"}
              name={"totalprice"}
              placeholder={Translate("orders", "totalprice")}
              text={Translate("orders", "totalprice")}
              type={"text"}
              value={totalPrice}
              workfun={handleTotalPriceChange}
              error={deliveryDetailsError.price}
              stclass={deliveryDetailsError.price ? "borderError" : ""}
            />
          </div>
          <div className="InFlx Stclmnf" style={{ flex: "1 1 0px", gap: "12px" }}>
            <GroupeForm
              id={"notetodriver"}
              name={"notetodriver"}
              placeholder={Translate("orders", "notetodriver")}
              text={Translate("orders", "notetodriver")}
              type={"text"}
              value={noteToDriver}
              workfun={handleNoteToDriverChange}
              textarea={true}
            />
            <div 
              className={`InFlx Stclmnf FlWd ${disablePointsDelivery ? "notAllowed" : ""}`}
            >
              <div className="InFlx AlgnItm">
                <CheckBoxForm
                  id="points-delivery-checkbox"
                  name="points_delivery"
                  check={pointsDeliveryCheck}
                  workfun={handlePointsDeliveryChange}
                  disabled={disablePointsDelivery}
                  rmMarg={true}
                />
                <div className="InFlx AlgnItm">
                  <span style={{ marginTop: "3px" }} >{TowCoinsSvg} </span> 
                  <span className="StWdDst">{Translate("orders", "ponitsdelivery")}</span>
                </div>
              </div>
            </div>

            {
              pointsDelivery
              &&
              <div className="InFlx" style={{ gap: "8px" }}>
                <div className="InFlx FrInp AlgnItm" style={{ padding: "2px 8px", borderRadius: "90px", gap: "6px" }}>
                  <span>{Translate("orders", "cost")}: </span> <span className="InFlx AlgnItm">{SmallCoinSvG}</span> <span>{(required_points ?? 0)}</span>
                </div>
                <div className="InFlx FrInp AlgnItm" style={{ padding: "2px 8px", borderRadius: "90px", gap: "6px" }}>
                  <span>{Translate("orders", "your_points")}: </span> <span className="InFlx AlgnItm">{SmallCoinSvG}</span> <span>{(dataDeliveryV2?.current_points ?? 0)}</span>
                </div>
              </div>
            }

            {
              disablePointsDelivery
              &&
              <p className="DlMg StSmlS FlWd StOpcVal">
                * {Translate("orders", "insufficient_points")} 
              </p>
            }
          </div>
        </div>
        <div className="FlWd" style={{ height: "1px", backgroundColor: "var(--fntClr)", opacity: 0.2 }}/>
        <div className="InFlx Stclmnf sTmftAu" style={{ gap: "8px" }}>
          <div className="InFlx AlgnItm spcBtwn" style={{ gap: "12px" }}>
            <h4 className="DlMg">{Translate("orders", "customerwillpay")}</h4>
            <h4 className="DlMg">{totalPrice} {store_currency}</h4>
          </div>
          <div className="InFlx AlgnItm spcBtwn" style={{ gap: "12px" }}>
            <h4 className="DlMg">{Translate("orders", "wewillget")}</h4>
            {
              pointsDelivery
              ?
                <div className="InFlx AlgnItm" style={{ gap: "8px" }}>
                  <span style={{ marginTop: "2px" }}>{SmallCoinSvG}</span> 
                  <span>{required_points}</span>
                </div>
              :
                <h4 className="DlMg">
                  {`${delivery_price} ${store_currency}`}
                </h4>
            }
          </div>
          <div className="InFlx AlgnItm spcBtwn" style={{ gap: "12px" }}>
            <h4 className="DlMg">{Translate("orders", "youwillget")}</h4>
            <h4 className={`DlMg lgfSize${price_diff >= 0 ? " Bgstatus_01" : " Bgstatus_50"}`}>
              {`${price_diff} ${store_currency}`}
            </h4>
          </div>
        </div>
      </div>
      <div className="FlWd" style={{ height: "1px", backgroundColor: "var(--fntClr)", opacity: 0.2 }}/>
      <div className="MrAot" style={{ width: "300px" }}>
        <Button
          gray={disableSubmit}
          disabled={disableSubmit} 
          onClick={handleSubmit}
          BtnText={buttonTitle} 
          style={{ padding: "10px 0" }} 
        />
      </div>
    </>
  );
};

function mapState(state: any) {
  
  const { dtStoreinfo } = state.user;

  const {
    GetingWilaya,
    dataWilaya,
    GetingSDWilayas,
    GetigCommune,
    DataCommune,
    GettingDeliveryV2,
    dataDeliveryV2,
    dataPickupPoints,
    GettingPickupPoints,
  } = state.common;

  return {
    dtStoreinfo,
    GetingWilaya,
    dataWilaya,
    GetingSDWilayas,
    GetigCommune,
    DataCommune,
    GettingDeliveryV2,
    dataDeliveryV2,
    dataPickupPoints,
    GettingPickupPoints,
  }
};

const actionCreators = {
  SendAlert: alertActions.SendAlert,
  GetWilayas: commonActions.Getwilayas,
  Getcommunes: commonActions.Getcommunes,
  GetDeliveryV2: commonActions.GetDeliveryV2,
  GetPickupPoints: commonActions.GetPickupPoints,
  ClearPickupPointsData: commonActions.ClearPickupPointsData,
};

const connectedDeliveryDetailsForm = connect(mapState, actionCreators)(DeliveryDetailsForm)

export { connectedDeliveryDetailsForm as DeliveryDetailsForm };