import React, { useEffect, useState } from 'react';
import ContainerPage from '../_Common/ContainerPage';
import { Translate } from '../../../utils/lang/translate';
import Card from '../_Common/Card/Card';
import DeliveryAgent from '../../../svgs/DeliveryAgent';
import ProductIcon from '../../../svgs/ProductIcon';
import PersonIcon from '../../../svgs/PersonIcon';
import { Order2Submit, RequestStatus, OrderDetails} from '../../../types';
import { alertActions, ordersActions } from '../../../actions';
import { connect } from 'react-redux';
import { Link, RouterChildContext, useHistory, useParams } from 'react-router-dom';
import { ProductsForm } from './components/ProductsForm';
import CustomerInfoForm from './components/CustomerInfoForm';
import { DeliveryDetailsForm } from './components/DeliveryDetailsForm';


type StepType = "PRODUCT_DETAILS" | "CLIENT_INFO" | "DELIVERY_DETAIL";
 

const steps: Record<StepType, { text: string, icon: (props: React.SVGAttributes<SVGElement>) => JSX.Element }> = {
  PRODUCT_DETAILS: {
    text: Translate("product", "productdetails"),
    icon: ProductIcon,
  },
  CLIENT_INFO: {
    text: Translate("orders", "clientinfo"),
    icon: PersonIcon,
  },
  DELIVERY_DETAIL: {
    text: Translate("orders", "deliverydetails"),
    icon: DeliveryAgent,
  }
}

type AddOrderProps = {
  SendAlert: (code: string, text: string, action: string) => void,
  dataProfile: any,
  dtStoreinfo: any,
  AddOrder: (order: Order2Submit) => void,
  OrderAdded: RequestStatus,
  dataAddOrder: OrderDetails,
  ClearAddOrder: () => void,
  GettingOrderById: RequestStatus,
  orderData: OrderDetails,
  GetOrder: (order_id: string) => void,
  UpdateOrder: (data: Order2Submit, order_id: string) => void,
  OrderUpdating: RequestStatus,
  DataUpdateOrder: OrderDetails 
};

const AddOrder = ({
  dataProfile,
  dtStoreinfo,
  SendAlert,
  AddOrder,
  OrderAdded,
  dataAddOrder,
  ClearAddOrder,
  GetOrder,
  GettingOrderById,
  orderData,
  UpdateOrder,
  OrderUpdating,
  DataUpdateOrder
}: AddOrderProps) => {

  const INITIAL_ORDER: Order2Submit = {
    commune: null,
    customer_name: null,
    customer_phone: null,
    destination_text: null,
    products: null,
    total_price: null,
    express: false,
    pickup_point: null,
    external_order_id: null,
    note_to_driver: null,
    delivery_type: 1,
    source: 1,
    use_points: false,
    wilaya: null,
    is_exchange: false,
  }

  const history = useHistory<RouterChildContext['router']['history']>();

  const { orderId } = useParams<{ orderId: string }>();
  
  const [order, setOrder] = useState<Order2Submit>(INITIAL_ORDER);

  const [currentStep, setCurrentStep] = useState<StepType>("PRODUCT_DETAILS");
  
  const currentStepIndex = Object.keys(steps).findIndex((key, _) => key === currentStep);
  const isLastStep = (index: number) => index === Object.keys(steps).length - 1;
  
  useEffect(() => {
    if(orderId !== "new"){
      GetOrder(orderId);
    }
  }, [orderId])

  useEffect(() => {
    
    if(OrderAdded === "2") {
      ClearAddOrder();
      SendAlert("41", "Successfuly added new order!", "");
      history.push(`/orders?display_id=${dataAddOrder.display_id}`);
    }
    else if(OrderAdded === "3") {
      ClearAddOrder();
      SendAlert("50", "Failed to add new order!", "");
    };

    if(GettingOrderById === '2') {
      if(orderId !== "new"){
        setOrder({
          commune: orderData.commune,
          customer_name: orderData.customer_name,
          customer_phone: orderData.customer_phone,
          delivery_type: orderData.delivery_type,
          destination_text: orderData.destination_text,
          express: false,
          external_order_id: orderData.external_order_id,
          pickup_point: orderData.pickup_point,
          note_to_driver: orderData.note_to_driver,
          products: orderData.products,
          source: orderData.source,
          total_price: orderData.total_price,
          use_points: orderData.use_points,
          wilaya: parseInt(orderData.wilaya_id ?? "0"),
          is_exchange: orderData.is_exchange,
        });
      }else{
        setOrder(INITIAL_ORDER);
      }
    };

    if(GettingOrderById === '3') {
      SendAlert("50", "Failed to fetch order data!", "");
    };

    if(OrderUpdating === '2') {
      SendAlert("41", "Successfuly updated order data!", "");
      history.push(`/orders?display_id=${DataUpdateOrder.display_id}`);
    }

    if(OrderUpdating === "3") {
      SendAlert("50", "Failed to update order data!", "");
    };
 

  }, [OrderAdded, GettingOrderById, OrderUpdating, orderId]);

  {/** add use effect hook to run clean up logic */}
  {/** move steps rendering logic to steps enum */}
  return (
    <ContainerPage 
      page_title={
        orderId === "new" ? 
          Translate("orders", "addneworder") 
        : 
          <div className="StDrc">
            <Link className="StOpcVal" to={"/orders"}> {/** needs to point to /orders + source filter */}
              {Translate("titles", "order")}
            </Link>
            <div className="Inlflx StWdDst">
              {Translate("orders", "flech")}
            </div>
            <div className="Inlflx">{Translate("orders", "editorder")}</div>
            <div className="Inlflx StWdDst">#</div>
            <div className="Inlflx">{orderData?.display_id ?? ""}</div>
          </div>
      }
    >
      
      <Card style={{ gap: "30px" }}>
        <div className="InFlx AlgnItm JstfCnt">
          {
            Object.keys(steps).map((key, index) => {
              const Icon = steps[key as StepType].icon;
              return (
                <>
                  <div 
                    key={index}
                    className="InFlx Stclmnf AlgnItm" 
                    style={{ gap: "10px" }}
                  >
                    <div 
                      className="InFlx AlgnItm JstfCnt"
                      style={{ 
                        height: "48px", 
                        width: "48px", 
                        borderRadius: "50%",
                        border: "4px solid #0B78EA",
                        backgroundColor: currentStepIndex >= index ? "#0B78EA" : "transparent"
                      }}
                    >
                      <Icon 
                        height={24}
                        width={24} 
                        fill={currentStepIndex >= index ? "#FFF" : "#0B78EA"}
                      />
                    </div>
                    <h4 
                      className="DlMg blueapp"
                    >
                      {steps[key as StepType].text}
                    </h4>
                  </div>
                  {
                    !isLastStep(index)
                    &&
                    <div 
                      className="FlWd BrdBlDtd"
                      style={{ 
                        height: "4px",
                        maxWidth: "300px",
                        border: "2px dashed #0B78EA" 
                      }}
                    />
                  }
                </>
              )
            })
          }
        </div>
        {
          currentStep === 'PRODUCT_DETAILS'
          &&
          <ProductsForm 
            orderProductsData={
              {
                orderProducts : order.products ? order.products.map(
                  (item, _) => ({ 
                    product_id: item.product_id, 
                    logistical_description: item.logistical_description ?? "", 
                    quantity: item.quantity,
                    is_exchange: item.is_exchange 
                  })
                ) : null,
                external_order_id: order.external_order_id ?? null
              }
            }
            onFormSubmit={
              (data) => {
                setOrder(prev => ({ ...prev, products: data.products, external_order_id: data.external_order_id }));
                setCurrentStep(Object.keys(steps)[(currentStepIndex + 1) % 3] as StepType);
              }
            } 
          />
        }
        {
          currentStep === 'CLIENT_INFO'
          &&
          <CustomerInfoForm
            customerInfo={
              order.customer_name && order.customer_phone
              ?
              {
                name: order.customer_name,
                phone: order.customer_phone,
                second_phone: ""
              }
              : null
            }
            onFormSubmit={
              (data) => {
                setOrder(prev => ({ ...prev, customer_name: data.name, customer_phone: data.phone }));
                setCurrentStep(Object.keys(steps)[(currentStepIndex + 1) % 3] as StepType);
              }
            }
          />
        }
        {
          currentStep === "DELIVERY_DETAIL"
          &&
          <DeliveryDetailsForm
            onFormSubmit={(data) => {
              if(orderId !== "new") {
                UpdateOrder({ ...order, ...data }, orderId);
              }else {
                AddOrder({ ...order, ...data });
              }
              setOrder(prev => ({ ...prev, ...data }));
            }}
            loading={OrderAdded === '1' || OrderUpdating === '1'}
            orderDeliveryDetails={
              order.commune && 
              order.wilaya && 
              order.total_price
              ? { ...order }
              : null
            }
          />
        }
      </Card>
    </ContainerPage>
  );
}
 
function mapState(state: any) {

  const { dataProfile, dtStoreinfo } = state.user;
  const { dataAddOrder, OrderAdded, GettingOrderById, orderData, OrderUpdating, DataUpdateOrder } = state.orders

  return {
    dataProfile,
    dtStoreinfo,
    OrderAdded,
    dataAddOrder,
    GettingOrderById,
    orderData,
    OrderUpdating,
    DataUpdateOrder
  };
}

const actionCreators = {
  SendAlert: alertActions.SendAlert,
  AddOrder: ordersActions.AddOrder,
  ClearAddOrder: ordersActions.ClearAddOrder,
  GetOrder: ordersActions.GetOrder,
  UpdateOrder: ordersActions.UpdateOrder
};

const connectedAddOrder = connect(mapState, actionCreators)(AddOrder);

export { connectedAddOrder as AddOrder };
 
