import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  productActions,
  ordersActions,
  exchangeActions,
  commonActions,
  alertActions,
} from "../../../actions";
import ContainerPage from "../_Common/ContainerPage";
import { FeatureLoked } from "../_Common/FeatureLoked";
import { Translate } from "../../../utils/lang/translate";
import GroupeForm from "../../_Common/GroupeForm";
import SelectForm from "../../_Common/SelectForm";
import SelectFormSearch from "../../_Common/SelectFormSearch";
import ToggleSwitch from "../../_Common/ToggleSwitch";
import Button from "../../_Common/Button";
import Loader from "../../_Common/Loader";
import { Link } from "react-router-dom";
import { Event, Select } from "../_Common";
import Cookies from "js-cookie";
import { CloseSvg, ExchangeSvg, SmallCoinSvG, TowCoinsSvg, deliveryTruck } from "../_Common/IconSvg";
import {
  CommuneInterface,
  ErrorInterface,
  OrderProductInterface,
  ProductInterface,
  SubmitOrderInterface,
  wilayaInterface,
  stopDeskWilayaInterface
} from "../../../types";
import {
  checkPhoneNumberValidity,

  getParamsFromUrl,
} from "../../../utils/helpers";
import CheckBoxForm from "../../_Common/CheckBoxForm";
import { MAX_TOTAL_PRICE_DZ, MAX_TOTAL_PRICE_TN } from "../../../constants/orders.constants";
const lang = Cookies.get("lang");

const PRODUCT_INIT: OrderProductInterface = {
  product_id: "",
  quantity: 0,
  logistical_description: "",
  defective: false,
  is_exchange: false,
};

type SwicthOptionProps = {
  optionId: 1 | 2,
  onOptionChange: (option: 1 | 2) => void,
  currentOption: 1 | 2
};

const SwitchOptionButton = ({ optionId, onOptionChange, currentOption }: SwicthOptionProps) => {
  return (
    <div
      onClick={
        () => {
          optionId === 1 ?
            Event("EXCHANGE_ORDER", "USE_HOME_DELIVERY_OPTION_BUTTON", "CLICK_EVENT")
          :
            Event("EXCHANGE_ORDER", "USE_STOP_DESK_OPTION_BUTTON", "CLICK_EVENT")
          onOptionChange(optionId);
        }
      } 
      className="col-half InFlx AlgnItm JstfCnt responsive-text" 
      style={{ 
        borderRadius: "50px", 
        padding: "10px 0", 
        cursor: "pointer", 
        backgroundColor: `${currentOption === optionId ? "#008AFF": "transparent"}` 
      }}
    >
      {
        optionId === 1 ?
          Translate("orders", "home")
        :
          Translate("orders", "stpdsk")
      }
    </div>
  );
}

const excludeProduct = (
  allProducts: ProductInterface[],
  excluded: OrderProductInterface
) => {
  return allProducts.filter(
    (el) => el.logistical_description !== excluded.logistical_description
  );
};

const ExchangePage = ({
  data,
  dataProfile,
  dtStoreinfo,
  OrderGeting,
  dataWilaya,
  DataCommune,
  ExchangeAdded,
  GetingDelivery,
  dataDelivery,
  OrdersList,
  ProductFinded,
  match,
  dataAddExchange,
  GetingWilaya,
  GetigCommune,
  GetOrders,
  GetCountry,
  Getwilayas,
  GetAllProduct,
  ClearStore,
  Getcommunes,
  GetDelivery,
  AddExchange,
  SendAlert,
  history,
  GetingSDWilayas,
  dataStopDeskWilaya,
  GetStopdeskWilayas
}: any) => {
  var deliveryHome = 1;
  const [stopDesk, setStopDesk] = useState<stopDeskWilayaInterface | null>(null);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [orderId, setOrderId] = useState<string | null>(null);
  const [products, setProducts] = useState([PRODUCT_INIT]);
  const [orderProducts, setOrderProducts] = useState<OrderProductInterface[]>([]);
  const [replaceProducts, setReplaceProducts] = useState<
    OrderProductInterface[][]
  >([]);
  const [extraProducts, setExtraProducts] = useState<OrderProductInterface[]>(
    []
  );
  const [commune, setCommune] = useState<CommuneInterface | null>(null);
  const [wilaya, setWilaya] = useState<wilayaInterface | null>(null);
  const [orderPrice, setOrderPrice] = useState<string>("0");
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState("0");
  const [noteToDriver, setNoteToDriver] = useState("");
  const [error, setError] = useState<ErrorInterface>({});
  const [isExpressDelivery, setIsExpressDelivery] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [productList, setProductList] = useState<ProductInterface[]>([]);
  const [pageTitle, setPageTitle] = useState<JSX.Element>(
    <div>{Translate("orders", "echangeorder")}</div>
  );
  const [buttonTitle, setButtonTitle] = useState<string>(
    Translate("exchanges", "submit")
  );
  const [externalWilaya, setExternalWilaya] = useState<string | null>(null);
  const [externalCommune, setExternalCommune] = useState<number | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [customerName, setCustomerName] = useState<string | null>(null);
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState<string | null>(
    null
  );
  const [address, setAddress] = useState<string | null>(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState<any | null>();
  const [freeDelivery, setFreeDelivery] = useState(false);
  const [sufficientBalance, setSufficientBalance] = useState(true);
  const [pointsDelivery, setPointsDelivery] = useState(false);
  const [sufficientPoints, setSufficientPoints] = useState(true);
  const hasAddPermission = dataProfile.is_store || dataProfile.add_order;
  const hasEditPermission = dataProfile.is_store || dataProfile.change_order;

  useEffect(() => {
    document.title = "Maystro Delivrey - " + Translate("titles", "exchanges");
    const { idorder } = match.params;
    GetOrders("?display_id_or_external_id=" + idorder, false);
    GetCountry();
    GetAllProduct();
    return () => {
      ClearStore("CLEAR_COMMON");
    };
  }, []);

  useEffect(() => {
    if (dtStoreinfo?.country?.id){
      GetStopdeskWilayas(dtStoreinfo?.country?.id);
      Getwilayas(dtStoreinfo.country.id);
    } 
  }, [dtStoreinfo?.country?.id]);

  useEffect(() => {
    if (selectedOption === 1) {
      if(commune){
        GetDelivery(commune?.id, isExpressDelivery, selectedOption);
      }
    }
    if(selectedOption === 2){
      if(stopDesk){
        GetDelivery(stopDesk.center_commune, false, selectedOption);
      }
    }
  }, [commune, stopDesk, selectedOption]);

  useEffect(() => {
    if (wilaya && typeof wilaya[0] === "number")
      Getcommunes("?wilaya=" + wilaya[0]);
  }, [wilaya]);

  useEffect(() => {
    if (OrderGeting === "2") {
      const { idorder } = match.params;
      const orderData = OrdersList.ressuc.list.results.find(
        (elem: any) => elem.display_id === idorder
      );

      if (orderData) {
        const externalTitle = (
          <div className="StDrc">
            <Link className="StOpcVal" to={"/orders"}>
              {Translate("titles", "order")}
            </Link>
            <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
            <div className="Inlflx">{Translate("orders", "echangeorder")}</div>
            <div className="Inlflx StWdDst">#</div>
            <div className="Inlflx">{orderData.display_id}</div>
          </div>
        );
        if(orderData.delivery_type !== 2){
          setExternalWilaya(orderData.wilaya)
          setNoteToDriver(orderData.note_to_driver);
          setIsExpressDelivery(orderData.express);
          setExternalCommune(orderData.commune); // to be fixed!
          setAddress(
            orderData.destination_text === "Please add the address here"
              ? ""
              : orderData.destination_text
          );
        }
        setOrderId(orderData.id);
        setProducts(
          orderData.products.map((prod: OrderProductInterface) => ({
            ...prod,
            quantity: 0,
          }))
        );
        setOrderProducts(orderData.products);
        setReplaceProducts(
          orderData.products.map((_prod: OrderProductInterface) => [])
        );
        setCustomerName(orderData.customer_name);
        setCustomerPhoneNumber(orderData.customer_phone);
        setNoteToDriver(orderData.note_to_driver);
        setDeliveryPrice(orderData.price ?? 0);
        setOrderPrice(orderData.product_price);
        setTotalPrice("0");
        setSelectedOption(orderData.delivery_type);
        setPointsDelivery(orderData.use_points);
        setIsNotFound(false);
        setPageTitle(externalTitle);
        setButtonTitle(Translate("orders", "save"));
      }
    }
  }, [OrderGeting]);

  useEffect(() => {
    if(GetingDelivery === "1"){
      setLoadingPrice(true);
    }else{
      if (GetingDelivery === "2") {
        setDeliveryPrice(dataDelivery.delivery_price);
        if(freeDelivery && dataDelivery.min_total_price !== 0) {
          setFreeDelivery(false);
        }
        setSufficientBalance(dataDelivery.min_total_price === 0);
        setSufficientPoints(dataDelivery.current_points > dataDelivery.required_points);
        setOrderPrice((Number(totalPrice) - dataDelivery.delivery_price).toString());
      }
      setLoadingPrice(false);    
    }
  }, [GetingDelivery]);

  useEffect(() => {
    if (ProductFinded === "2") setProductList(data.list);
  }, [ProductFinded]);

  useEffect(() => {
    if (ExchangeAdded === "3" && isSubmitting) {
      document.getElementById("Container-root")?.scrollTo(0, 0);
      if (dataAddExchange?.response?.data)
        setAlertMessage(
          Translate("error", dataAddExchange.response.data.detail)
        );
      if(dataAddExchange?.response?.data?.use_points){
        SendAlert("50", dataAddExchange?.response?.data?.use_points[0], ""); 
      }else{
        SendAlert("50", Translate("alert", "exchfail"), "a02");
      }
      setIsSubmitting(false);
      setSubmitting(false);
      setError({});
    }
    if (ExchangeAdded === "2" && isSubmitting) {
      GetOrders("");
      setIsSubmitting(false);
      SendAlert("41", Translate("alert", "exchadded"), "a01");
      history.push("/orders");
    }
  }, [ExchangeAdded, isSubmitting]);

  useEffect(() => {
    const wilayas = dataWilaya as wilayaInterface[] | null;
    if (externalWilaya && GetingWilaya === "1" && Array.isArray(wilayas)) {
      const wilaya = wilayas.find((wilaya) => wilaya[1] === externalWilaya);
      if (wilaya) {
        handleWilayaSelect(wilaya);
        setExternalWilaya(null);
      }
    }
  }, [externalWilaya, GetingWilaya]);

  useEffect(() => {
    const communes = DataCommune as CommuneInterface[] | null;
    if (externalCommune && GetigCommune === "1" && Array.isArray(communes)) {
      const commune = communes.find(
        (commune) => commune.id === externalCommune
      );
      if (commune) {
        handleCommuneSelect(commune);
        setExternalCommune(null);
      }
    }
  }, [externalCommune, GetigCommune]);

  useEffect(() => {
    const { idorder } = match.params;
    if(
        GetingSDWilayas === "1"
        && OrderGeting === "2"  
    ){
      const orderData = OrdersList.ressuc.list.results.find(
        (elem: any) => elem.display_id === idorder
      );
      const desk = (dataStopDeskWilaya as stopDeskWilayaInterface[]).find((value) => value.name_lt === orderData.wilaya);
      if(desk) setStopDesk(prev => !prev ? desk : prev);
    }
  }, [GetingSDWilayas, OrderGeting])

  const handleChange = (e: React.ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    if (name === "express_delivery") {
      if (wilaya && selectedOption === 1) {
        GetDelivery(commune?.id, true, selectedOption);
      }
      setIsExpressDelivery(value === "true");
    }

    if (name === "orderprice" && dtStoreinfo?.country?.id === 1) {
      const prix = value === "" ? "0" : value.replace(/\D/g, "");
      setOrderPrice(prix ? (Number(prix) - (deliveryPrice ?? 0)).toString() : "0");
      setTotalPrice(value);
      if(GetingDelivery === "2" && prix < dataDelivery.min_total_price){
        setError(prev => ({ ...prev, price: Translate("error", "code8") }));
      }else{
        if(selectedOption === 1 && Number(prix) > MAX_TOTAL_PRICE_DZ){
          setError(prev => ({ ...prev, price: "total price can't be greater than 100000" }));
        }else{
          setError({});
        }
      }
    } else if (name === "orderprice" && dtStoreinfo?.country?.id === 2) {
      const prix = value === "" ? "0" : value.replace(/[^0-9.]/g, "");
      setOrderPrice(prix ? (Number(prix) - (deliveryPrice ?? 0)).toString() : "0");
      setTotalPrice(value);
      if(GetingDelivery === "2" && prix < dataDelivery.min_total_price && !(pointsDelivery && sufficientPoints)){
        setError(prev => ({ ...prev, price: Translate("error", "code8") }));
      }else{
        if(selectedOption === 1 && Number(prix) > MAX_TOTAL_PRICE_TN){
          setError(prev => ({ ...prev, price: "total price can't be greater than 2000" }));
        }else{
          setError({});
        }
      }
    }
  };

  const handleStopDeskSelect = (stopDeskWilaya: stopDeskWilayaInterface) => {
    setStopDesk(stopDeskWilaya);
    GetDelivery(stopDeskWilaya.center_commune, false, selectedOption);
  };

  const handleChangeProduct = (e: React.ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    let updatesProducts = [...products];
    const tabn = name.split("_");
    const index = parseInt(tabn[1]);
    updatesProducts[index - 1].quantity = parseInt(value);
    if (parseInt(value) > 0) updatesProducts[index - 1].is_exchange = true;
    setProducts(updatesProducts);
  };

  const handleReplaceProduct = (e: React.ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    let tabn = name.split("_");
    const index = parseInt(tabn[1]);
    const isExtraProduct = tabn[0].startsWith("extra");
    if (tabn[0].endsWith("productname")) {
      let theProduct = productList.find(
        (elem) => elem.logistical_description === value
      );
      if (theProduct) {
        setIsNotFound(false);
        if (isExtraProduct) {
          let updatedProducts = [...extraProducts];
          // changing productName for extra products
          updatedProducts[index - 1].product_id = theProduct.id;
          updatedProducts[index - 1].logistical_description = value;
          setExtraProducts(updatedProducts);
        } else {
          let updatedProducts = [...replaceProducts];
          updatedProducts[index - 1][0].product_id = theProduct.id;
          updatedProducts[index - 1][0].logistical_description = value;

          setReplaceProducts(updatedProducts);
        }
      }
      if (!theProduct) {
        dtStoreinfo.stock_managed ? setIsNotFound(true) : setIsNotFound(false);
        if (isExtraProduct) {
          let updatedProducts = [...extraProducts];
          updatedProducts[index - 1].logistical_description = value;
          updatedProducts[index - 1].product_id = "";
          setExtraProducts(updatedProducts);
        } else {
          let updatedProducts = [...replaceProducts];
          updatedProducts[index - 1][0].logistical_description = value;
          updatedProducts[index - 1][0].product_id = "";
          setReplaceProducts(updatedProducts);
        }
      }
    } else {
      // changing quantity
      if (isExtraProduct) {
        let updatedProducts = [...extraProducts];
        updatedProducts[index - 1].quantity = parseInt(value);
        setExtraProducts(updatedProducts);
      } else {
        let updatedProducts = [...replaceProducts];
        updatedProducts[index - 1][0].quantity = parseInt(value);
        setReplaceProducts(updatedProducts);
      }
    }
  };

  const handleCommuneSelect = (newCommune: CommuneInterface) => {
    if(wilaya && selectedOption === 1){
      GetDelivery(newCommune?.id, isExpressDelivery, deliveryHome);
      setCommune(newCommune);
    }
  };

  const handleWilayaSelect = (newWilaya: wilayaInterface) => {
    if (newWilaya && (!wilaya || wilaya[0] !== newWilaya[0])) {
      if (!externalWilaya) setCommune(null);
      setWilaya(newWilaya);
      Getcommunes("?wilaya=" + newWilaya[0]);
    }
    if (!newWilaya) {
      if (!externalWilaya) setCommune(null);
      setWilaya(null);
    }
  };

  const AddFieldProduct = (index: number) => {
    Event(
      "ADD_EXCHANGE",
      "USE_EXCHANGE_WITH_OTHER_PRODUCT_BUTTON",
      "CLICK_EVENT"
    );
    let updatedReplaceProducts = [...replaceProducts];
    updatedReplaceProducts[index].push({ product_id: "", quantity: 1, is_exchange: false });
    setReplaceProducts(updatedReplaceProducts);
  };

  const AddExtraFieldProduct = () => {
    Event("ADD_EXCHANGE", "USE_ADD_ANOTHER_PRODUCT_BUTTON", "CLICK_EVENT");
    if (dtStoreinfo.stock_managed)
      setExtraProducts([
        ...extraProducts,
        {
          product_id: "",
          quantity: 1,
          is_exchange: false
        },
      ]);
    else
      setExtraProducts([
        ...extraProducts,
        {
          product_id: "",
          quantity: 1,
          exchange_reason: null,
          is_exchange: false,
          defective: false,
        },
      ]);
  };

  const RemoveFieldProduct = (index: number) => {
    Event(
      "ADD_EXCHANGE",
      "USE_REMOVE_EXCHANGE_WITH_OTHER_PRODUCT_BUTTON",
      "CLICK_EVENT"
    );
    if (replaceProducts.length > 0) {
      let updatedProducts = [...replaceProducts];
      updatedProducts[index].splice(0, 1);
      setReplaceProducts(updatedProducts);
    }
  };

  const RemoveExtraProductField = (index: number) => {
    Event("ADD_EXCHANGE", "USE_REMOVE_ADD_OTHER_PRODUCT_BUTTON", "CLICK_EVENT");
    if (extraProducts.length > 0) {
      let updatedProducts = [...extraProducts];
      updatedProducts.splice(index, 1);
      setExtraProducts(updatedProducts);
    }
  };

  const setProductDefective = (index: number) => {
    let updatedProducts = [...products];
    Event(
      "ADD_EXCHANGE",
      "TOGGLE_PRODUCT_DEFECTIVE",
      `SET_TO_${
        updatedProducts[index].defective ? "DEFECTIVE" : "NOT_DEFECTIVE"
      }`
    );
    updatedProducts[index].defective = !updatedProducts[index].defective;
    setProducts(updatedProducts);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    Event("ADD_EXCHANGE", "USE_SUBMIT_BUTTON", "CLICK_EVENT");
    setSubmitting(true);
    let isError = false;
    let order: SubmitOrderInterface = {},
      newError: ErrorInterface = {};
    if(selectedOption === 1){
    if (wilaya && typeof wilaya[0] === "number") {
        order.wilaya = wilaya[0];
      } else {
        newError.wilaya = Translate("error", "eo1");
        isError = true;
      }
      if (commune?.id) {
        order.commune = commune.id;
      } else {
        newError.commune = Translate("error", "eo2");
        isError = true;
      }
      if (address) {
        order.destination_text = address;
      }else{
        order.destination_text = "";
      }
    }else{
      if (stopDesk && typeof stopDesk.code === "number") {
        order.wilaya = stopDesk.code;
      } else {
        isError = true;
      }
      if (stopDesk?.center_commune) {
        order.commune = stopDesk?.center_commune;
      } else {
        isError = true;
      }
      order.destination_text = "";
    }

    if (customerPhoneNumber) {
      order.customer_phone = customerPhoneNumber;
    } else {
      newError.phoneNumber = Translate("error", "eo5");
      isError = true;
    }
    if (customerName) {
      order.customer_name = customerName;
    } else {
      newError.name = Translate("error", "eo4");
      isError = true;
    }

    if(Number(totalPrice) >= 0 && totalPrice !== "" && !error.price){
      if(Number(totalPrice) >= (dataDelivery?.min_total_price ?? 0)){
        order.total_price = freeDelivery ? 0 : Number(totalPrice);
      }else{
        if(!(pointsDelivery && sufficientPoints)){
          newError.price = Translate("error", "code8");
          isError = true;
        }
      }
    }else{
      if(error.price){
        newError.price = error.price;
      }else{
        newError.price = Translate("error", "eo6");
      }
      isError = true;
    }

    if (deliveryPrice) {
      order.price = deliveryPrice;
    }
    if (selectedOption) {
      order.delivery_type = selectedOption;
    }
    if(pointsDelivery && sufficientPoints){
      order.use_points = true;
    }else{
      order.use_points = false;
    }
    // Check of new product's quantities
    let updatedReplaceProducts = [...replaceProducts];
    replaceProducts.forEach((prod, index) => {
      if (prod[0] && prod[0].quantity <= 0) {
        updatedReplaceProducts[index][0].error = {
          errquantity: Translate("error", "e13"),
        };
        isError = true;
      }
    });
    if (isError) {
      Event(
        "ADD_EXCHANGE",
        "ERROR_INFORMATION_ENTER",
        `[${Object.keys(newError).join(",")}]`
      );
      setError(newError);
      setReplaceProducts(updatedReplaceProducts);
      return;
    }

    if (hasAddPermission && !isNotFound) {
      setIsSubmitting(true);
      const isPremium = dtStoreinfo.stock_managed;
      if (isPremium) {
        let replacementProducts = replaceProducts
          .filter((el) => el.length > 0)
          .map((el) => ({ ...el[0] }));
        // If a product is replaced with the same one, duplicate it in the request body
        products.forEach((prod, index) => {
          // If it's not replaced with a different one, add it to replacements
          if (prod.is_exchange && replaceProducts[index].length === 0)
            replacementProducts.push({ ...prod, is_exchange: false });
        });
        order.products = products
          .filter((el) => el.is_exchange)
          .concat(replacementProducts)
          .concat(extraProducts);
        // return
        let nbrExchanged =
          products.filter((prod) => prod.quantity > 0).length +
          replacementProducts.length;

        if (nbrExchanged === 0) {
          alert(Translate("error", "e12"));
          return;
        }
      } else {
        let replacementProducts = replaceProducts
          .filter((el) => el.length > 0)
          .map((el) => ({ ...el[0] }));
        // If a product is replaced with the same one, duplicate it in the request body
        products.forEach((prod, index) => {
          // If it's not replaced with a different one, add it to replacements
          if (prod.is_exchange && replaceProducts[index].length === 0)
            replacementProducts.push({ ...prod, is_exchange: false });
        });
        order.products = products
          .filter((el) => el.is_exchange)
          .concat(replacementProducts)
          .concat(extraProducts);
        let nbrExchanged =
          products.filter((prod) => prod.quantity > 0).length +
          replacementProducts.length;

        if (nbrExchanged === 0) {
          alert(Translate("error", "e12"));
          return;
        }
      }

      if (orderId) order.old_order = orderId;
      order.is_exchange = true;
      order.express = isExpressDelivery;
      order.note_to_driver = noteToDriver;
      Event("ADD_EXCHANGE", "SUBMIT_INFOS", "INFOS_VALID");

      AddExchange(order, isPremium);
    }
  };

  const source = useMemo(
    () => getParamsFromUrl("source"),
    [window.location.href]
  );
  const atLeastOneProductSelected = useMemo(
    () => products.some((prod) => prod.quantity > 0),
    [products]
  );

  if (!hasAddPermission && !source) {
    return (
      <ContainerPage page_title={Translate("orders", "addneworder")}>
        <FeatureLoked />
      </ContainerPage>
    );
  }
  if (!hasEditPermission && source) {
    return (
      <ContainerPage page_title={Translate("orders", "editorder")}>
        <FeatureLoked />
      </ContainerPage>
    );
  }
  const isLoading =
    ExchangeAdded === "1" || OrderGeting !== "2" || ProductFinded !== "2";

  return (
    <ContainerPage page_title={pageTitle}>
      {isLoading && <Loader />}
      <div className="ClSidTh StBrdTb StBgbrds widthAdd">
        {alertMessage && (
          <div className="FlWd TxtCn StRedClr">{alertMessage}</div>
        )}
        <form method="post" onSubmit={handleSubmit}>
          <ContainerPage subContainer page_title={""} small_title={true}>
            {Array.isArray(orderProducts) &&
              orderProducts.map((orderProduct, key) => {
                return (
                  <div key={key}>
                    <div className="FlWd">
                      <div
                        style={{ flexGrow: "1" }}
                        className="InFlx AlgnItm flxDrc"
                      >
                        <div className="Hlwd InFlx AlgnItm flxDrc">
                          <div
                            style={{ flexGrow: "0.02" }}
                            className="FlHg InFlx AlgnItm flxDrc"
                          >
                            <h2># {key + 1}</h2>
                          </div>
                          {orderProduct.picture && (
                            <div
                              style={{ flexGrow: "0.18" }}
                              className="FlHg InFlx AlgnItm"
                            ></div>
                          )}
                          <div
                            style={{ flexGrow: "0.8" }}
                            className="Mrg2 FlHg InFlx flxDrc"
                          >
                            <div
                              className={
                                "FlHg FlWd Stclmnf " + lang === "ar"
                                  ? "StFlxStr"
                                  : ""
                              }
                            >
                              <div className="InFlx flxDrc">
                                <h2 className="DlMg">
                                  {" "}
                                  {orderProduct.logistical_description}
                                </h2>
                              </div>
                              <div className="InFlx flxDrc">
                                <p className="DlMg">
                                  {Translate("exchanges", "deliveredqty")} :
                                  {orderProduct.quantity}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {
                          !dtStoreinfo.stock_managed
                          &&
                          <div className="Hlwd Mrg2">
                            <GroupeForm
                              id={"quantity_" + (key + 1)}
                              name={"quantity_" + (key + 1)}
                              placeholder={Translate(
                                "exchanges",
                                "exchangequantity"
                              )}
                              text={Translate(
                                "exchanges",
                                "exchangequantity"
                              )}
                              type={"number"}
                              min={0}
                              max={orderProduct.quantity}
                              value={products[key].quantity}
                              defaultValue={0}
                              workfun={handleChangeProduct}
                              stclass={
                                !atLeastOneProductSelected ||
                                products[key].quantity > orderProduct.quantity
                                  ? "borderError"
                                  : ""
                              }
                            />
                          </div>
                        }     
                      </div>
                      <div className="FlWd">
                      {
                        dtStoreinfo.stock_managed
                        &&
                        <div className="FlWd InFlx AlgnItm flxDrc">
                          <div className="Hlwd">
                            <GroupeForm
                              id={"quantity_" + (key + 1)}
                              name={"quantity_" + (key + 1)}
                              placeholder={Translate(
                                "exchanges",
                                "exchangequantity"
                              )}
                              text={Translate(
                                "exchanges",
                                "exchangequantity"
                              )}
                              type={"number"}
                              min={0}
                              max={orderProduct.quantity}
                              value={products[key].quantity}
                              defaultValue={0}
                              workfun={handleChangeProduct}
                              stclass={
                                !atLeastOneProductSelected ||
                                products[key].quantity > orderProduct.quantity
                                  ? "borderError"
                                  : ""
                              }
                            />
                          </div>
                          <div className="Hlwd FlHg Mrg2 RlPs">
                            <div className="FlWd FlHg InFlx AlgnItm Stclmnf">
                              <div className="FlWd InFlx flxDrc SmMarS">
                                <p className="StSizLn DlMg">
                                  {Translate("exchanges", "productstat")}
                                </p>
                              </div>
                              <div className="InFlx AlgnItm flxDrc">
                                <div
                                  style={{ flexGrow: "0.2" }}
                                  className="FlHg InFlx flxDrc"
                                >
                                  <ToggleSwitch
                                    toggleHandler={() =>
                                      setProductDefective(key)
                                    }
                                  />
                                </div>

                                <div
                                  style={{ flexGrow: "0.8" }}
                                  className="FlHg InFlx flxDrc"
                                >
                                  <div
                                    className={
                                      "FlHg FlWd Stclmnf " + lang === "ar"
                                        ? "StFlxStr"
                                        : ""
                                    }
                                  >
                                    <div className="InFlx flxDrc">
                                      <b>
                                        {" "}
                                        {Translate(
                                          "exchanges",
                                            "productdefective"
                                        )}
                                      </b>
                                    </div>

                                    <p className="DlMg StOpcVal">
                                      {Translate(
                                        "exchanges",
                                        "productdefectiveseparat"
                                      )}{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }

                      {replaceProducts[key].length > 0 &&
                        replaceProducts[key].map((el, pos) => (
                          <div className="FlWd InFlx Stclmnf">
                            <div className="FlWd InFlx flxDrc">
                              <div className="Hlwd">
                                <SelectFormSearch
                                  id={"productname_" + (key + 1)}
                                  name={"productname_" + (key + 1)}
                                  index={"logistical_description"}
                                  option={excludeProduct(
                                    productList,
                                    orderProduct
                                  )}
                                  text={Translate("product", "productname")}
                                  workfun={handleReplaceProduct}
                                  placeholder={
                                    ProductFinded === "2"
                                      ? Translate("product", "productname")
                                      : Translate("alert", "load")
                                  }
                                  value={
                                    replaceProducts[key][pos]
                                      .logistical_description
                                  }
                                  stclass={
                                    replaceProducts[key][pos].product_id ===
                                    ""
                                      ? ""
                                      : ""
                                  }
                                />
                              </div>
                              <div className="Hlwd Mrg2 RlPs">
                                <div className="Flwd InFlx flxDrc AlgnItm">
                                  <GroupeForm
                                    id={"quantity_" + (key + 1)}
                                    name={"quantity_" + (key + 1)}
                                    placeholder={Translate(
                                      "orders",
                                      "quantity"
                                    )}
                                    text={Translate("orders", "quantity")}
                                    type={"number"}
                                    value={
                                      replaceProducts[key][pos].quantity
                                    }
                                    min={1}
                                    workfun={handleReplaceProduct}
                                    style={{ width: "80%" }}
                                    stclass={
                                      isNaN(
                                            replaceProducts[key][pos].quantity
                                      ) ||
                                      replaceProducts[key][pos].quantity < 1
                                        ? "borderError"
                                        : ""
                                    }
                                  />
                                  <div
                                    className="CrsPoi InFlx JstfCnt"
                                    style={{
                                      alignItems: "flex-end",
                                          margin: "5% 0% 0% 2%",
                                    }}
                                    onClick={() => RemoveFieldProduct(key)}
                                  >
                                    {CloseSvg}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          ))}
                          <div className="FlWd InFlx flxDrc">
                            {/* error?.erproduct && <span>{error.erproduct}</span> */}
                            {isNotFound && (
                              <div className="FlWd StdirLn">
                                <span className="CrsPoi">
                                  {" " + Translate("product", "prdntfond")}
                                </span>
                                <Link
                                  to="/products/add"
                                  className="CrsPoi StBle"
                                >
                                  {" " + Translate("product", "addnewproduct")}
                                </Link>
                              </div>
                            )}
                            {
                              replaceProducts[key].length === 0 &&
                                products[key].quantity > 0 && (
                                  <div className="FlWd StdirLn">
                                    <span
                                      className="StBle CrsPoi"
                                      onClick={() => AddFieldProduct(key)}
                                    >
                                      <div
                                        className={
                                          "StMrtg2 StHgVls InFlx AlgnItm StBlFil StSmallSvg"
                                        }
                                      >
                                        <p>
                                          {" " +
                                            Translate(
                                              "exchanges",
                                              "exchangewithanother"
                                          )}
                                        </p>{" "}
                                          {ExchangeSvg}
                                      </div>
                                    </span>
                                  </div>
                                )
                            }
                          </div>
                        </div>
                    </div>
                  </div>
                );
              })}
          </ContainerPage>
          <ContainerPage subContainer>
            {extraProducts.length === 0 && (
              <div className="FlWd StdirLn">
                <span className="StBle CrsPoi" onClick={AddExtraFieldProduct}>
                  <div
                    className={
                      "StMrtg2 StHgVls InFlx AlgnItm StBlFil StSmallSvg"
                    }
                  >
                    <p>{" " + Translate("exchanges", "sellanotherproduct")}</p>{" "}
                    {ExchangeSvg}
                  </div>
                </span>
              </div>
            )}
            <div>
              {extraProducts.length > 0 && (
                <div className="FlWd InFlx flxDrc">
                  {" "}
                  <h4>{Translate("exchanges", "newproducts")}</h4>
                </div>
              )}
              {extraProducts.length > 0 &&
                extraProducts.map((el, idx) => (
                  <div className="FlWd InFlx Stclmnf">
                    <div className="FlWd InFlx flxDrc">
                      <div className="Hlwd">
                        <SelectFormSearch
                          id={"extraproductname_" + (idx + 1)}
                          name={"extraproductname_" + (idx + 1)}
                          index={"logistical_description"}
                          option={productList}
                          text={Translate("product", "productname")}
                          workfun={handleReplaceProduct}
                          placeholder={
                            ProductFinded === "2"
                              ? Translate("product", "productname")
                              : Translate("alert", "load")
                          }
                          value={extraProducts[idx].logistical_description}
                          stclass={
                            extraProducts[idx].product_id === "" ? "" : ""
                          }
                        />
                      </div>
                      <div className="Hlwd Mrg2 RlPs">
                        <div className="Flwd InFlx flxDrc AlgnItm">
                          <GroupeForm
                            id={"extraquantity_" + (idx + 1)}
                            name={"extraquantity_" + (idx + 1)}
                            placeholder={Translate("orders", "quantity")}
                            text={Translate("orders", "quantity")}
                            type={"number"}
                            value={extraProducts[idx].quantity}
                            min={1}
                            workfun={handleReplaceProduct}
                            style={{ width: "80%" }}
                            stclass={
                              isNaN(extraProducts[idx].quantity) ||
                              extraProducts[idx].quantity < 1
                                ? "borderError"
                                : ""
                            }
                          />
                          <div
                            className="CrsPoi InFlx JstfCnt"
                            style={{
                              alignItems: "flex-end",
                              margin: "5% 0% 0% 2%",
                            }}
                            onClick={() => RemoveExtraProductField(idx)}
                          >
                            {CloseSvg}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="FlWd InFlx flxDrc">
                      {/* error?.erproduct && <span>{error.erproduct}</span> */}
                      {isNotFound && (
                        <div className="FlWd StdirLn">
                          <span className="CrsPoi">
                            {" " + Translate("product", "prdntfond")}
                          </span>
                          <Link to="/products/add" className="CrsPoi StBle">
                            {" " + Translate("product", "addnewproduct")}
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </ContainerPage>
          <ContainerPage
            subContainer
            page_title={Translate("orders", "clientinfo")}
            small_title={true}
          >
            <div className="FlWd InFlx flxDrc">
              <div className="Hlwd">
                <GroupeForm
                  id={"customerName"}
                  name={"customerName"}
                  placeholder={Translate("orders", "customername")}
                  text={Translate("orders", "customername")}
                  type={"text"}
                  value={customerName}
                  workfun={(e: React.ChangeEvent) => {
                    const { value } = e.target as HTMLInputElement;
                    setCustomerName(value);
                  }}
                  error={error?.name}
                  stclass={!customerName ? "borderError" : ""}
                />
              </div>
              <div className="Hlwd Mrg2">
                <GroupeForm
                  id={"customerphonenumber"}
                  name={"customerphonenumber"}
                  placeholder={Translate("orders", "customerphonenumber")}
                  text={Translate("orders", "customerphonenumber")}
                  type={"tel"}
                  pattern="^(((\+216)?[1-9]\d{7})|((0?|(00|\+)?213)[56789]\d{8}))$"
                  value={customerPhoneNumber}
                  workfun={(e: React.ChangeEvent) => {
                    const { value } = e.target as HTMLInputElement;
                    setCustomerPhoneNumber(value);
                  }}
                  error={error?.phoneNumber}
                  stclass={
                    !checkPhoneNumberValidity(customerPhoneNumber!)
                      ? "borderError"
                      : ""
                  }
                />
              </div>
            </div>
          </ContainerPage>
          <ContainerPage
            subContainer
            withOvrl={true}
            page_title={Translate("orders", "deliverydetails")}
            small_title={true}
          >
            <div className="FlWd InFlx responsFlx flxDrc" 
              style={{ height: "100%", marginBottom: "15px" }}
            >
              <div className="Hlwd">
                {
                  dtStoreinfo?.country?.id !== 2
                  &&
                  <div className="FlWd InFlx FrInp" 
                    style={{ borderRadius: "50px", marginBottom: "15px" }}
                  >
                    <SwitchOptionButton 
                      optionId={1} 
                      currentOption={selectedOption}
                      onOptionChange={setSelectedOption} 
                    />
                    <SwitchOptionButton 
                      optionId={2} 
                      currentOption={selectedOption}
                      onOptionChange={setSelectedOption} 
                    />
                  </div>
                }
                {
                  dtStoreinfo?.country?.id !== 2 && selectedOption === 2
                  ?
                  <div className="FlWd" style={{ marginBottom: "15px" }}>
                    <Select
                      label={Translate("orders", "stpdsk")}
                      placholder={Translate("orders", "stpdsk")}
                      search={true}
                      Options={
                        dataStopDeskWilaya
                        ?
                          dataStopDeskWilaya
                        :
                          []
                      }
                      fieldShow={lang === "ar" ? "name_ar" : "name_lt"}
                      name="stopdesk"
                      loading={GetingSDWilayas === "0"}
                      value={stopDesk}
                      onChange={handleStopDeskSelect}
                      maxSize="200px"
                      disabled={true}
                      containerClass={
                        isSubmitting && !stopDesk && selectedOption === 2 ? "borderError" : ""
                      }
                    />
                  </div>
                  :
                  <>
                    <GroupeForm
                      id={"adresse"}
                      name={"adresse"}
                      placeholder={Translate("orders", "adresse")}
                      text={Translate("orders", "adresse")}
                      type={"text"}
                      value={address}
                      workfun={(e: React.ChangeEvent) => {
                        const { value } = e.target as HTMLInputElement;
                        setAddress(value);
                      }}
                      error={error?.address}
                    />
                    <div
                      className="FlWd InFlx responsFlx flxDrc"
                      style={{ margin: "28px 0 28px 0" }}
                    >
                      <div className="Hlwd">
                        <Select
                          label={Translate("orders", "wilaya")}
                          placholder={Translate("orders", "citySearch")}
                          search={true}
                          Options={dataWilaya}
                          fieldShow={1}
                          name="wilaya"
                          loading={GetingWilaya === "0"}
                          value={wilaya}
                          onChange={handleWilayaSelect}
                          maxSize="200px"
                          containerClass={!wilaya ? "borderError" : ""}
                        />
                      </div>
                      <div className="Hlwd Mrg2">
                        <Select
                          label={Translate("orders", "district")}
                          placholder={Translate("orders", "districtSearch")}
                          search={true}
                          Options={DataCommune}
                          fieldShow={"name"}
                          name="commune"
                          loading={GetigCommune === "0"}
                          value={commune}
                          onChange={handleCommuneSelect}
                          maxSize="200px"
                          containerClass={!commune ? "borderError" : ""}
                        />
                      </div>
                    </div>
                  </>
                }
              </div>
              <div className="Hlwd Mrg2">
                {
                  selectedOption === 1
                  &&
                  <GroupeForm
                    id={"notetodriver"}
                    name={"notetodriver"}
                    placeholder={Translate("orders", "notetodriver")}
                    text={Translate("orders", "notetodriver")}
                    type={"text"}
                    value={noteToDriver}
                    workfun={(e: React.ChangeEvent) => {
                      const { value } = e.target as HTMLInputElement;
                        setNoteToDriver(value);
                      }}
                    textarea={true}
                  />
                }
                <div className={`InFlx Stclmnf FlWd ${sufficientBalance ? "" : "notAllowed"}`}>
                  <div className="InFlx AlgnItm">
                    <CheckBoxForm
                      id="free-delivery-checkbox"
                      name="free_delivery"
                      check={freeDelivery && sufficientBalance}
                      workfun={() => {
                        setFreeDelivery(prev => {
                          if(!prev) setPointsDelivery(false);
                          return !prev;
                        });
                      }}
                      disabled={!sufficientBalance}
                    />
                    <div className="InFlx AlgnItm">
                      <span style={{ marginTop: "3px" }} > {deliveryTruck} </span> 
                      <span className="StWdDst"> Free delivery </span>
                    </div>
                  </div>
                  { 
                    freeDelivery && sufficientBalance
                    &&
                    <p className="DlMg StSmlS FlWd text-gray">
                      the delivery fees will be deducted from your Maystro balance 
                    </p>  
                  }
                  {
                    !sufficientBalance
                    &&
                    <p className="DlMg StSmlS FlWd">
                      Your balance is insufficient 
                    </p>
                  }
                </div>
                <div className={`InFlx Stclmnf FlWd ${sufficientPoints && !pointsDelivery ? "" : "notAllowed"}`}>
                    <div className="InFlx AlgnItm">
                      <CheckBoxForm
                        id="points-delivery-checkbox"
                        name="points_delivery"
                        check={pointsDelivery && sufficientPoints}
                        workfun={() => {
                          Event("ADD_EXCHANGE", "USE_POINTS_CHECKBOX", "CLICK_EVENT");
                          setPointsDelivery(prev => {
                            if(!prev) setFreeDelivery(false);
                            return !prev;
                          });
                        }}
                        disabled={!sufficientPoints && pointsDelivery}
                      />
                      <div className="InFlx AlgnItm">
                        <span style={{ marginTop: "3px" }} >{TowCoinsSvg} </span> 
                        <span className="StWdDst">{Translate("orders", "ponitsdelivery")}</span>
                      </div>
                    </div>
                  </div>
                  {
                    pointsDelivery
                    &&
                    <div className="InFlx" style={{ gap: "8px" }}>
                      <div className="InFlx FrInp AlgnItm" style={{ padding: "2px 8px", borderRadius: "90px", gap: "6px" }}>
                        <span>{Translate("orders", "cost")}: </span> <span className="InFlx AlgnItm">{SmallCoinSvG}</span> <span>{(dataDelivery?.required_points ?? 0)}</span>
                      </div>
                      <div className="InFlx FrInp AlgnItm" style={{ padding: "2px 8px", borderRadius: "90px", gap: "6px" }}>
                        <span>{Translate("orders", "your_points")}: </span> <span className="InFlx AlgnItm">{SmallCoinSvG}</span> <span>{(dataDelivery?.current_points ?? 0)}</span>
                      </div>
                    </div>
                  }
                  {
                    pointsDelivery && !sufficientPoints
                    &&
                    <p className="DlMg StSmlS FlWd" style={{ marginTop: "5px" }}>
                      {Translate("orders", "insufficient_points")} 
                    </p>
                  }
              </div>
            </div>
            <div
              className="FlWd InFlx responsFlx flxDrc"
              style={{ height: "100%" }}
            >
              <div className="Hlwd">
                <GroupeForm
                  id={"orderprice"}
                  name={"orderprice"}
                  placeholder={Translate("orders", "totalprice")}
                  text={Translate("orders", "totalprice")}
                  type={"text"}
                  value={totalPrice}
                  workfun={handleChange}
                  error={error.price}
                  stclass={(submitting && !totalPrice || error.price) ? "borderError" : ""}
                />
              </div>
              <div className="Hlwd Mrg2">
                { 
                  wilaya && wilaya[0] === 16 && selectedOption === 1 
                  && 
                  (
                    <SelectForm
                      id={"expressdelivery"}
                      name={"express_delivery"}
                      option={[
                        { text: Translate("orders", "no"), val: false },
                        { text: Translate("orders", "yes"), val: true },
                      ]}
                      value={isExpressDelivery}
                      text={Translate("orders", "expressdelivery")}
                      workfun={handleChange}
                    />
                  )
                }
              </div>
            </div>
          </ContainerPage>
          <span
            className="DsBlc FlWd"
            style={{
              height: "1px",
              backgroundColor: "var(--fntClr)",
              opacity: ".2",
            }}
          ></span>
          <div className="FlWd TxtCn StRedClr">
            {commune &&
              !commune.id &&
              commune.name &&
              Translate("error", "Order out of our service")}
          </div>
          <div className="InFlx StMarMx stbold StFlxStr">
            <div className="InFlx Stclmnf">
              <span className="stfSize"> {Translate("orders", "totalprice")} </span>
              <span className="stfSize"> {Translate("orders", "deliveryprice")} </span>
              <span className="lgfSize">{Translate("orders", "orderprice")}</span>
            </div>
            <div className="InFlx Stclmnf min-width align-items-end">
              {
                loadingPrice
                ?
                <span className="Mrg2 stfSize">
                  {Translate("alert", "load")}
                </span>
                :
                <span className="Mrg2 stfSize">
                  {
                    lang == "ar"
                    ?
                      dtStoreinfo?.country?.id === 2
                      ?
                        freeDelivery
                        ?
                          "0" + "   " + dtStoreinfo.country.ar_currency
                          :
                          Number(totalPrice).toFixed(2) + "   " + dtStoreinfo.country.ar_currency  
                        :
                        freeDelivery
                        ?
                          "0" + "   " + dtStoreinfo.country.ar_currency
                          :
                          Number(totalPrice) + "   " + dtStoreinfo.country.ar_currency  
                    : 
                      dtStoreinfo?.country?.id === 2
                      ?
                      freeDelivery
                        ?
                          "0" + "   " + dtStoreinfo.country.lt_currency
                        :
                          Number(totalPrice).toFixed(2) + "   " + dtStoreinfo.country.lt_currency
                      :
                      freeDelivery
                        ?
                          "0" + "   " + dtStoreinfo.country.lt_currency
                        :
                          Number(totalPrice) + "   " + dtStoreinfo.country.lt_currency
                  }
                </span>
              }
              {
                loadingPrice
                ?
                <span className="Mrg2 stfSize">
                  {Translate("alert", "load")}
                </span>
                :
                <span className="Mrg2 stfSize">
                  {
                    pointsDelivery && sufficientPoints
                    ?
                      <div className="InFlx AlgnItm" style={{ gap: "8px" }}>
                        <span style={{ marginTop: "2px" }}>{SmallCoinSvG}</span> 
                        <span>{(dataDelivery?.required_points ?? 0)}</span>
                      </div>
                    :
                      lang == "ar"
                      ?
                        deliveryPrice + "   " + dtStoreinfo.country.ar_currency
                      : 
                        deliveryPrice + "   " + dtStoreinfo.country.lt_currency
                  }
                </span>
              }
              <span 
                className={`
                  Mrg2 lgfSize 
                  ${
                    Number(orderPrice) >= 0 && !freeDelivery 
                  ?
                    "Bgstatus_01"
                  :
                    freeDelivery && sufficientBalance
                    ? 
                      "Bgstatus_50" 
                    : 
                      Number(orderPrice) < 0
                      ?
                        pointsDelivery && sufficientPoints
                        ?
                          "Bgstatus_01"
                        :
                          "Bgstatus_50"
                      :
                        "Bgstatus_01"
                }
              `}
              >
                {
                  lang == "ar"
                  ?
                    dtStoreinfo?.country?.id === 2
                    ?
                      freeDelivery && sufficientBalance
                      ?
                        (-deliveryPrice) + "   " + dtStoreinfo.country.ar_currency
                      :
                        pointsDelivery && sufficientPoints
                        ?
                          Number(totalPrice).toFixed(2) + "   " + dtStoreinfo.country.ar_currency
                        :
                          Number(orderPrice).toFixed(2) + "   " + dtStoreinfo.country.ar_currency
                    :
                      freeDelivery && sufficientBalance
                      ?
                        (-deliveryPrice) + "   " + dtStoreinfo.country.ar_currency
                      :
                        pointsDelivery && sufficientPoints
                        ?
                          totalPrice + "   " + dtStoreinfo.country.ar_currency
                        :
                          orderPrice + "   " + dtStoreinfo.country.ar_currency
                  :
                    dtStoreinfo?.country?.id === 2
                    ?
                    freeDelivery
                    ?
                      (-deliveryPrice) + "   " + dtStoreinfo.country.lt_currency
                      :
                      pointsDelivery && sufficientPoints
                        ?
                          Number(totalPrice).toFixed(2) + "   " + dtStoreinfo.country.lt_currency
                        :
                          Number(orderPrice).toFixed(2) + "   " + dtStoreinfo.country.lt_currency
                    :
                    freeDelivery
                    ?
                      (-deliveryPrice) + "   " + dtStoreinfo.country.lt_currency
                      :
                      pointsDelivery && sufficientPoints
                        ?
                          totalPrice + "   " + dtStoreinfo.country.lt_currency
                        :
                          orderPrice + "   " + dtStoreinfo.country.lt_currency 
                }
              </span>
            </div> 
          </div>
          <div className="MrAot StMarMx responseDiv" style={{ width: "25%" }}>
          {
              loadingPrice
              ?
              <Button gray disabled BtnText={Translate("alert", "load")}/>
              :
                Number(totalPrice) < 0
                ?
                  <Button danger disabled BtnText={Translate("error", "eo6")} />
                :
                  <Button BtnText={buttonTitle} />
            }
          </div>
        </form>
      </div>
    </ContainerPage>
  );
};

function mapState(state: any) {
  const {
    dataStopDeskWilaya,
    GetingSDWilayas,
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    GetingDelivery,
    dataDelivery,
    dataCountry,
    GetingPays,
  } = state.common;
  const { ProductFinded, data } = state.product;
  const { OrderGeting, OrdersList } = state.orders;
  const { ExchangeAdded, dataAddExchange } = state.exchanges;

  const { DataSearch, Searching } = state.search;
  const { dtStoreinfo, dataProfile } = state.user;
  return {
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    data,
    ProductFinded,
    ExchangeAdded,
    dataAddExchange,
    GetingDelivery,
    dataDelivery,
    OrderGeting,
    OrdersList,
    DataSearch,
    Searching,
    dtStoreinfo,
    dataProfile,
    dataCountry,
    GetingPays,
    GetingSDWilayas,
    dataStopDeskWilaya
  };
}

const actionCreators = {
  Getwilayas: commonActions.Getwilayas,
  Getcommunes: commonActions.Getcommunes,
  GetAllProduct: productActions.GetAllProduct,
  GetDelivery: commonActions.GetDelivery,
  AddExchange: exchangeActions.AddExchange,
  GetOrders: ordersActions.GetOrders,
  UpdateUnfOrder: ordersActions.UpdateUnfOrder,
  SendAlert: alertActions.SendAlert,
  ClearStore: commonActions.ClearStore,
  GetCountry: commonActions.GetCountry,
  GetStopdeskWilayas: commonActions.GetStopDeskWilayas
};

const ConnectedExchangePage = connect(mapState, actionCreators)(ExchangePage);

export { ConnectedExchangePage as ExchangePage };
