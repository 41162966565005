import React, { Component, Fragment } from "react";
import ElementSide from "../_Common/ElementSide";
import {
  DashboardSvg,
  OrdersSvg,
  UntOrdersSvg,
  PaymentsSvg,
  SettingsSvg,
  PickupsSvg,
  BackupSvg,
  ConfirmationSvg,
  StockSvg,
  ProductSvg,
  TakenProdSvg,
  ReceiveProdSvg,
  ShopSvg
} from "./SideBarSvg";
import {
  PersonalInfoSvg,
  storeStaffIcon,
  webhookIcon,
  PeopleSvg,
  ExchangeSvg,
  RewardsSvg,
  MoneySvg
} from "../_Common/IconSvg";
import { Translate } from "../../../utils/lang/translate";
import { Link } from "react-router-dom";
import { alertActions, ordersActions, exchangeActions, userActions } from "../../../actions";
import { connect } from "react-redux";
import SideBarLoader from "./SideBarLoader";
import ElementSideDrop from "../_Common/ElementSideStockDropDown";
import { initGAPg, Event } from "../_Common";
import logoSideBar from "../../../utils/img/maystro-blue.svg";
import logoSideBarNoText from "../../../utils/img/maystro-blue-notext.svg";
import logoAmbassador from "../../../utils/img/maystro-ambassador.svg";
import logoAmbassadorMinified from "../../../utils/img/maystro-ambassador-minified.svg";
import OokadoSidebarImage from "../../../utils/img/ookado-sidebar-img.png";
import "./Sidebar.css";

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areSettingsVisible: false,
      areSettingsinfo: false,
    };
    this.handleSettingsDropDown = this.handleSettingsDropDown.bind(this);
    this.handleSettingsInfoDropDown =
      this.handleSettingsInfoDropDown.bind(this);
  }

  componentDidMount() {
    this.props.GetUnfOrdersCount();
    if(this.props.dtStoreinfo?.stock_managed === false){
      this.props.GetUnprintedExchangesCount();
    }
    initGAPg();
  }

  handleSettingsDropDown() {
    this.setState({
      ...this.state,
      areSettingsVisible: !this.state.areSettingsVisible,
    });
  }
  handleSettingsInfoDropDown() {
    this.setState({
      ...this.state,
      areSettingsinfo: !this.state.areSettingsinfo,
    });
  }

  render() {
    const {
      OrderGeting,
      OrdersList,
      GetinunfOrd,
      dataInfOrd,
      dataProfile,
      dtStoreinfo,
      sideState,
      dataInfOrdCount,
      dataCountExchanges,
      UpdateShowOokadoModal
    } = this.props;
    let pathorder = "/orders";
    let pathunf = "/unfulfilled/order";
    if (OrderGeting === "2") {
      pathorder = "/orders" + OrdersList.filter;
    }
    if (GetinunfOrd === "2") {
      pathunf = "/unfulfilled/order" + (dataInfOrd.filter ?? "");
    }

    return (
      <div className={`sidebar ${sideState && "sidebar-thin"} ClSidTh StBxSh stbd HdOvrfl FxPs`} >
        <div
          style={{
            paddingBottom: "53px",
            height: "calc(100vh - 69px)",
          }}
        >
          <div className="TxtCn">
            <Link to="/dashboard">
              {sideState ? (
                <img
                  className="IcnSiz2"
                  style={{ marginTop: "10px" }}
                  alt="Maystro sidebar logo"
                  src={this.props.dataRewards?.tier.name === "Ambassador" ? logoAmbassadorMinified : logoSideBarNoText}
                />
              ) : (
                <>
                  <img
                    className="StWdIlg sidebar-active-item-decoration"
                    id="Logo_Maystro"
                    alt="Maystro sidebar logo"
                    src={this.props.dataRewards?.tier?.name === "Ambassador" ? logoAmbassador : logoSideBar}
                  />
                  <img
                    className="IcnSiz2 sidebar-thin-logo"
                    style={{ marginTop: "10px" }}
                    alt="Maystro sidebar logo"
                    src={this.props.dataRewards?.tier?.name === "Ambassador" ? logoAmbassadorMinified : logoSideBarNoText}
                  />
                </>
              )}
            </Link>
          </div>
          <span style={{ padding: "5px 0", display: "block" }}></span>
          {(dataProfile.is_store || dataProfile.add_order) && (
            <div className="TxtCn SmMarS2">
              <Link className="InFlx JstfCnt" to="/order/new">
                <button className="InFlx AlgnItm JstfCnt Fntcl FntfMin Brdblue rmOut StBrdRd BdgGc CrsPoi BtNset StPrwd">
                  <span
                    style={{ fontWeight: "bold", fontSize: "20px", margin: "0 5px" }}
                  >
                    +
                  </span>
                  {
                    !sideState
                    &&
                    <span className="sidebar-active-item-decoration">
                      {Translate("sidebar", "addorder")}
                    </span>
                  }
                </button>
              </Link>
              {
                !sideState
                && (
                  <div 
                    onClick={() => {
                      UpdateShowOokadoModal(true);
                      Event("OOKADO_MODAL", "OPEN_OOKADO_MODAL", "CLICK_EVENT");
                    }}
                    className="ookado_sidebar_image_container StBrdRd StPrwd" 
                    style={{ backgroundImage: `url(${OokadoSidebarImage})` }} 
                  />
                )
              }
            </div>
          )}

          <div rol="top_side">
            <ElementSide
              clickEvent={() => {
                Event(
                  "DASHBOARD",
                  "CLICK_ELEMENT_SIDEBAR_DASHBOARD",
                  "CLICK_EVENT"
                );
              }}
              reduce={sideState}
              icon={DashboardSvg}
              to={"/dashboard"}
              active={this.props.pathname === "/dashboard"}
              text={Translate("sidebar", "dashboard")}
            />
            {(dataProfile.is_store || dataProfile.view_order) && (
              <Fragment>
                <ElementSide
                  clickEvent={() => {
                    Event(
                      "ORDERS",
                      "CLICK_ELEMENT_SIDEBAR_ORDERS",
                      "CLICK_EVENT"
                    );
                  }}
                  reduce={sideState}
                  icon={OrdersSvg}
                  to={pathorder}
                  active={this.props.pathname === "/orders"}
                  text={Translate("sidebar", "orders")}
                />
                {
                  !dtStoreinfo.stock_managed
                  &&
                  <ElementSide
                    clickEvent={() => {
                      Event(
                        "EXCHANGES",
                        "CLICK_ELEMENT_SIDEBAR_EXCHANGES",
                        "CLICK_EVENT"
                      );
                    }}
                    reduce={sideState}
                    icon={ExchangeSvg}
                    to={"/exchange-refund-list/2"}
                    active={
                      this.props.pathname === "/exchange-refund-list/2"
                    }
                    text={Translate("exchanges", "exchanges")}
                    notif
                    temp_orders_count={dataCountExchanges?.count} // here is the count of exchange order with unprinted slips
                  />
                }
                {
                  !dtStoreinfo.stock_managed
                  &&
                  <ElementSide
                    clickEvent={() => {
                      Event(
                        "REFUNDS",
                        "CLICK_ELEMENT_SIDEBAR_REFUNDS",
                        "CLICK_EVENT"
                      );
                    }}
                    reduce={sideState}
                    icon={MoneySvg}
                    to={"/exchange-refund-list/1"}
                    active={
                      this.props.pathname === "/exchange-refund-list/1"
                    }
                    text={Translate("refunds", "refunds")}
                  />
                }
                {dataProfile.intgraed &&
                  (dataProfile.is_store || dataProfile.view_order) && (
                    <ElementSide
                      clickEvent={() => {
                        Event(
                          "UNFULFILLED_ORDERS",
                          "CLICK_ELEMENT_SIDEBAR_UNFULFILLED_ORDERS",
                          "CLICK_EVENT"
                        );
                      }}
                      reduce={sideState}
                      icon={UntOrdersSvg}
                      to={pathunf}
                      active={
                        this.props.pathname === "/unfulfilled/order"
                      }
                      text={Translate("sidebar", "unfulfilledorders")}
                      notif
                      temp_orders_count={
                        dataInfOrdCount?.temp_orders_count
                      }
                    />
                  )}
              </Fragment>
            )}

            {(dataProfile.is_store || dataProfile.view_stock) && (
              <div rol="bottum_side">
                <div onClick={this.handleSettingsDropDown}>
                  <ElementSideDrop
                    icon={StockSvg}
                    select={this.state.areSettingsVisible}
                    reduce={sideState}
                    active={
                      this.props.pathname.indexOf("/stock") != "-1" ||
                      (this.props.pathname.split("/")[1] === "shop" &&
                        this.props.pathname.split("/")[2] === "product")
                    }
                    text={Translate("sidebar", "stock")}
                    to={"#"}
                  />
                </div>
                {this.state.areSettingsVisible && (
                  <div>
                    {dtStoreinfo.stock_managed ? (
                      <div style={{ marginLeft: "10px" }}>
                        <ElementSide
                          clickEvent={() => {
                            Event(
                              "STOCK",
                              "CLICK_ELEMENT_SIDEBAR_PRODUCTS",
                              "CLICK_EVENT"
                            );
                          }}
                          reduce={sideState}
                          icon={ProductSvg}
                          to="/stock/products"
                          active={
                            this.props.pathname === "/stock/products"
                          }
                          text={Translate("sidebar", "products")}
                        />

                        <ElementSide
                          clickEvent={() => {
                            Event(
                              "STOCK",
                              "CLICK_ELEMENT_SIDEBAR_VOUCHER_RECEIVE",
                              "CLICK_EVENT"
                            );
                          }}
                          reduce={sideState}
                          icon={ReceiveProdSvg}
                          to="/stock/receiveProduct"
                          active={
                            this.props.pathname ===
                            "/stock/receiveProduct"
                          }
                          text={Translate("sidebar", "receiveProd")}
                        />
                        <ElementSide
                          clickEvent={() => {
                            Event(
                              "STOCK",
                              "CLICK_ELEMENT_SIDEBAR_STOCK_RELEASE",
                              "CLICK_EVENT"
                            );
                          }}
                          reduce={sideState}
                          icon={TakenProdSvg}
                          to="/stock/stock-release"
                          active={
                            this.props.pathname === "/stock/stock-release"
                          }
                          text={"Stock release"}
                        />
                        <ElementSide
                          clickEvent={() => {
                            Event(
                              "STOCK",
                              "CLICK_ELEMENT_SIDEBAR_VOUCHER_TAKEN",
                              "CLICK_EVENT"
                            );
                          }}
                          reduce={sideState}
                          icon={TakenProdSvg}
                          to="/stock/takenProduct"
                          active={
                            this.props.pathname === "/stock/takenProduct"
                          }
                          text={Translate("sidebar", "takenProd")}
                        />
                      </div>
                    ) : (
                      <div style={{ marginLeft: "10px" }}>
                        <ElementSide
                          clickEvent={() => {
                            Event(
                              "STOCK",
                              "CLICK_ELEMENT_SIDEBAR_PRODUCTS",
                              "CLICK_EVENT"
                            );
                          }}
                          reduce={sideState}
                          icon={ProductSvg}
                          to="/stock/products"
                          active={
                            this.props.pathname === "/stock/products"
                          }
                          text={Translate("sidebar", "products")}
                        />

                        <ElementSide
                          clickEvent={() => {
                            Event(
                              "STOCK",
                              "CLICK_ELEMENT_SIDEBAR_VOUCHER_RECEIVE",
                              "CLICK_EVENT"
                            );
                          }}
                          reduce={sideState}
                          icon={ReceiveProdSvg}
                          to="/stock/receiveProduct"
                          active={
                            this.props.pathname ===
                            "/stock/receiveProduct"
                          }
                          text="Received"
                        />
                        <ElementSide
                          clickEvent={() => {
                            Event(
                              "STOCK",
                              "CLICK_ELEMENT_SIDEBAR_VOUCHER_TAKEN",
                              "CLICK_EVENT"
                            );
                          }}
                          reduce={sideState}
                          icon={TakenProdSvg}
                          to="/stock/takenProduct"
                          active={
                            this.props.pathname === "/stock/takenProduct"
                          }
                          text="Taken"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            {(dataProfile.is_store || dataProfile.view_payment) && (
              <ElementSide
                clickEvent={() => {
                  Event(
                    "PAYMENTS",
                    "CLICK_ELEMENT_SIDEBAR_PAYMENTS",
                    "CLICK_EVENT"
                  );
                }}
                reduce={sideState}
                icon={PaymentsSvg}
                to="/payments"
                active={this.props.pathname === "/payments"}
                text={Translate("sidebar", "payments")}
              />
            )}
            {(dataProfile.is_store || dataProfile.view_pickup) &&
              !dtStoreinfo.stock_managed && (
                <ElementSide
                  clickEvent={() => {
                    Event(
                      "PICKUPS",
                      "CLICK_ELEMENT_SIDEBAR_PICKUPS",
                      "CLICK_EVENT"
                    );
                  }}
                  reduce={sideState}
                  icon={PickupsSvg}
                  to="/pickups"
                  active={this.props.pathname === "/pickups"}
                  text={Translate("sidebar", "pickups")}
                />
              )}
            {(dataProfile.is_store || dataProfile.view_pickup) &&
              dtStoreinfo.stock_managed &&
              dtStoreinfo.id ===
                "3a2855aa-9319-481e-ac50-164083b8a297" && (
                <ElementSide
                  clickEvent={() => {
                    Event(
                      "PICKUPS",
                      "CLICK_ELEMENT_SIDEBAR_PICKUPS",
                      "CLICK_EVENT"
                    );
                  }}
                  reduce={sideState}
                  icon={PickupsSvg}
                  to="/pickups"
                  active={this.props.pathname === "/pickups"}
                  text={Translate("sidebar", "stockpickups")}
                />
              )}

            {dtStoreinfo.is_gold && (
              <ElementSide
                clickEvent={() => {
                  Event(
                    "CONFIRMATION",
                    "CLICK_ELEMENT_SIDEBAR_CONFIRMATION",
                    "CLICK_EVENT"
                  );
                }}
                reduce={sideState}
                icon={ConfirmationSvg}
                to="/confirmation"
                active={this.props.pathname === "/confirmation"}
                text={Translate("sidebar", "confirmation")}
              />
            )}
            <ElementSide
              clickEvent={() => {
                Event(
                  "SHOP",
                  "CLICK_ELEMENT_SIDEBAR_SHOP_TO_AYOR",
                  "CLICK_EVENT"
                );
              }}
              reduce={sideState}
              icon={ShopSvg}
              to="/shop"
              active={this.props.pathname === "/shop"}
              text={Translate("sidebar", "shop")}
            />
            <ElementSide
              clickEvent={() => {
                Event(
                  "REWARDS",
                  "CLICK_ELEMENT_SIDEBAR_REWARDS",
                  "CLICK_EVENT"
                );
              }}
              reduce={sideState}
              icon={RewardsSvg}
              to="/rewards"
              active={this.props.pathname === "/rewards"}
              text={Translate("RewardsPage", "rewards")}
              new
            />
          </div>
          <span
            className="DsBlc FlWd"
            style={{
              height: "1px",
              backgroundColor: "var(--fntClr)",
              margin: "14px 0",
              opacity: ".2",
            }}
          ></span>
          {
            dataProfile.is_store
            &&
            <div rol="bottom_side">
              <div onClick={this.handleSettingsInfoDropDown}>
                <ElementSideDrop
                  icon={SettingsSvg}
                  select={this.state.areSettingsinfo}
                  reduce={sideState}
                  active={this.props.pathname.split("/")[1] === "settings"}
                  text={Translate("sidebar", "settings")}
                />
              </div>
              {this.state.areSettingsinfo && (
                <div style={{ marginLeft: "10px" }}>
                  <ElementSide
                    clickEvent={() => {
                      Event(
                        "SETTINGS",
                        "CLICK_ELEMENT_SIDEBAR_PERSONAL_INFO",
                        "CLICK_EVENT"
                      );
                    }}
                    icon={PersonalInfoSvg}
                    reduce={sideState}
                    to="/settings/personal-info"
                    active={this.props.pathname === "/settings/personal-info"}
                    text={Translate("settings", "personalinfo")}
                  />
                  <ElementSide
                    icon={storeStaffIcon}
                    reduce={sideState}
                    clickEvent={() => {
                      Event(
                        "SETTINGS",
                        "CLICK_ELEMENT_SIDEBAR_STORE_INFO",
                        "CLICK_EVENT"
                      );
                    }}
                    to="/settings/store"
                    active={this.props.pathname == "/settings/store"}
                    text={Translate("settings", "storeinfo")}
                  />
                  <ElementSide
                    clickEvent={() => {
                      Event(
                        "SETTINGS",
                        "CLICK_ELEMENT_SIDEBAR_TEAM",
                        "CLICK_EVENT"
                      );
                    }}
                    icon={PeopleSvg}
                    reduce={sideState}
                    to="/settings/teams"
                    active={
                      this.props.pathname.indexOf("/settings/teams") !== -1
                    }
                    text={Translate("settings", "storeteam")}
                  />

                  <ElementSide
                    icon={webhookIcon}
                    reduce={sideState}
                    to="/settings/webhooks"
                    active={this.props.pathname === "/settings/webhooks"}
                    text={Translate("settings", "webhooks")}
                  />
                </div>
              )}

              <ElementSide
                clickEvent={() => {
                  Event(
                    "BACKUP",
                    "CLICK_ELEMENT_SIDEBAR_BACKUP",
                    "CLICK_EVENT"
                  );
                }}
                icon={BackupSvg}
                reduce={sideState}
                to="/backup"
                active={this.props.pathname.indexOf("/backup") != "-1"}
                text={Translate("sidebar", "backup")}
              />
              {/*(dataProfile.is_store || dataProfile.view_issue_store) && <ElementSide icon={IssuesSvg} to="/issues" active={this.props.pathname=="/issues"} text={Translate('sidebar','issues')}/>*/}
            </div>
          }
        </div>
      </div>
    );
  }
}
function mapState(state) {
  const { OrderGeting, OrdersList } = state.orders;
  const { GetinunfOrd, dataInfOrd, dataInfOrdCount, GetinunfOrdCount } = state.unfOrders;
  const { dataProfile, dtStoreinfo, gtStoreInf } = state.user;
  const { sideState } = state.sidebar;
  const { GettingUnprintedExchangesCount, dataCountExchanges } = state.exchanges;
  const { dataRewards } = state.rewards;
  return {
    OrderGeting,
    OrdersList,
    GetinunfOrd,
    dataInfOrd,
    dataProfile,
    dtStoreinfo,
    gtStoreInf,
    sideState,
    dataInfOrdCount,
    GetinunfOrdCount,
    GettingUnprintedExchangesCount,
    dataCountExchanges,
    dataRewards,
  };
}

const actionCreators = {
  SendAlert: alertActions.SendAlert,
  GetUnfOrdersCount: ordersActions.GetUnfOrdersCount,
  GetUnprintedExchangesCount: exchangeActions.GetUnprintedExchangesCount,
  UpdateShowOokadoModal: userActions.UpdateShowOokadoModal,
};

const connectedSideBar = connect(mapState, actionCreators)(SideBar);
export { connectedSideBar as SideBar };
